@font-face {
    font-family: SF Pro Display;
    src: url("../fonts/SF-Pro-Display-Light.woff2") format("woff2"), url("../fonts/SF-Pro-Display-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: SF Pro Display;
    src: url("../fonts/SF-Pro-Display-Regular.woff2") format("woff2"), url("../fonts/SF-Pro-Display-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: SF Pro Display;
    src: url("../fonts/SF-Pro-Display-Medium.woff2") format("woff2"), url("../fonts/SF-Pro-Display-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: SF Pro Display;
    src: url("../fonts/SF-Pro-Display-Semi-Bold.woff2") format("woff2"), url("../fonts/SF-Pro-Display-Semi-Bold.woff") format("woff");
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: SF Pro Display;
    src: url("../fonts/SF-Pro-Display-Bold.woff2") format("woff2"), url("../fonts/SF-Pro-Display-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: Georgia;
    src: url(../fonts/Georgia-Regular.ttf);
    font-weight: 400
}

@font-face {
    font-family: Georgia-italic;
    src: url(../fonts/Georgia-Italic.ttf);
    font-weight: 400
}
