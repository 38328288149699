.btn {
  white-space: nowrap;
  padding: 14px 30px;
  cursor: pointer;
  outline: none;
  transition: .2s ease;
  font-size: 16px;
  min-width: 190px; }
  .btn.btn-sm {
    padding: 8px 25px;
    font-size: 12px; }
  .btn.btn-es {
    font-size: 12px;
    padding: 3px 20px; }
  .btn.btn-xs {
    font-size: 12px;
    padding: 3px 20px; }
  .btn.btn-lg {
    padding: 12px 90px; }
  .btn.btn-solid {
    color: #ffffff; }
    .btn.btn-solid.loading {
      position: relative;
      color: transparent !important; }
      .btn.btn-solid.loading:after {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: transparent;
        border: 2px solid transparent;
        border-top-color: #ffffff;
        animation: .8s spin linear infinite;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto; }
    .btn.btn-solid:disabled {
      opacity: .6;
      cursor: no-drop; }
    .btn.btn-solid.btn-info {
      background-color: #0091ff;
      border: 1px solid #0091ff; }
      .btn.btn-solid.btn-info:hover {
        background-color: #2da4ff;
        border: 1px solid #2da4ff; }
      .btn.btn-solid.btn-info:disabled {
        background-color: #0091ff;
        border: 1px solid #0091ff; }
    .btn.btn-solid.btn-warning {
      background-color: #ffb822;
      border: 1px solid #ffb822; }
      .btn.btn-solid.btn-warning:hover {
        background-color: #df9f16;
        border: 1px solid #df9f16; }
      .btn.btn-solid.btn-warning:disabled {
        background-color: #ffb822;
        border: 1px solid #ffb822; }
    .btn.btn-solid.btn-success {
      background-color: #34bfa3;
      border: 1px solid #34bfa3; }
      .btn.btn-solid.btn-success:hover {
        background-color: #28a58c;
        border: 1px solid #28a58c; }
      .btn.btn-solid.btn-success:disabled {
        background-color: #34bfa3;
        border: 1px solid #34bfa3; }
    .btn.btn-solid.btn-danger {
      background-color: #f4516c;
      border: 1px solid #f4516c; }
      .btn.btn-solid.btn-danger:hover {
        background-color: #d91f3e;
        border: 1px solid #d91f3e; }
      .btn.btn-solid.btn-danger:disabled {
        background-color: #f4516c;
        border: 1px solid #f4516c; }
    .btn.btn-solid.btn-metal {
      background-color: #dcdcdc;
      border: 1px solid #dcdcdc;
      color: #404040; }
      .btn.btn-solid.btn-metal:hover {
        background-color: #c8c4c4;
        border: 1px solid #c8c4c4; }
      .btn.btn-solid.btn-metal:disabled {
        background-color: #dcdcdc;
        border: 1px solid #dcdcdc;
        color: #404040; }
  .btn.btn-outline {
    color: #ffffff;
    background: transparent; }
    .btn.btn-outline.loading {
      position: relative;
      color: transparent !important; }
      .btn.btn-outline.loading:after {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: transparent;
        border: 2px solid transparent;
        animation: .8s spin linear infinite;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto; }
    .btn.btn-outline:disabled {
      opacity: .6;
      cursor: no-drop; }
    .btn.btn-outline.btn-info {
      border: 1px solid #0091ff;
      color: #0091ff; }
      .btn.btn-outline.btn-info.loading:after {
        border-top-color: #0091ff; }
      .btn.btn-outline.btn-info:hover {
        background-color: #0091ff;
        color: #ffffff; }
      .btn.btn-outline.btn-info:disabled {
        border: 1px solid #0091ff;
        color: #0091ff;
        background-color: #ffffff; }
    .btn.btn-outline.btn-warning {
      border: 1px solid #ffb822;
      color: #ffb822; }
      .btn.btn-outline.btn-warning.loading:after {
        border-top-color: #ffb822; }
      .btn.btn-outline.btn-warning:hover {
        background-color: #ffb822;
        color: #ffffff; }
      .btn.btn-outline.btn-warning:disabled {
        border: 1px solid #ffb822;
        color: #ffb822;
        background-color: #ffffff; }
    .btn.btn-outline.btn-success {
      border: 1px solid #34bfa3;
      color: #34bfa3; }
      .btn.btn-outline.btn-success.loading:after {
        border-top-color: #34bfa3; }
      .btn.btn-outline.btn-success:hover {
        background-color: #34bfa3;
        color: #ffffff; }
      .btn.btn-outline.btn-success:disabled {
        border: 1px solid #34bfa3;
        color: #34bfa3;
        background-color: #ffffff; }
    .btn.btn-outline.btn-danger {
      border: 1px solid #f4516c;
      color: #f4516c; }
      .btn.btn-outline.btn-danger.loading:after {
        border-top-color: #f4516c; }
      .btn.btn-outline.btn-danger:hover {
        background-color: #f4516c;
        color: #ffffff; }
      .btn.btn-outline.btn-danger:disabled {
        border: 1px solid #f4516c;
        color: #f4516c;
        background-color: #ffffff; }
    .btn.btn-outline.btn-metal {
      border: 1px solid #dcdcdc;
      color: #404040; }
      .btn.btn-outline.btn-metal.loading:after {
        border-top-color: #dcdcdc; }
      .btn.btn-outline.btn-metal:hover {
        background-color: #dcdcdc;
        border-color: #dcdcdc; }
      .btn.btn-outline.btn-metal:disabled {
        border: 1px solid #dcdcdc;
        color: #404040;
        background-color: #ffffff; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  outline: 0;
  display: flex;
  justify-content: center;
  color: #20383D !important;
  /* align-items: center; */ }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: 1500;
  background: rgba(25, 25, 35, 0.5);
  opacity: 0.6; }

.mx-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  max-height: calc(100% - 50px);
  position: relative;
  z-index: 4000;
  max-width: 1100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

.modal-content::-webkit-scrollbar {
  display: none; }

.modal-content {
  position: relative; }

.modal-row {
  grid-row-gap: 20px;
  row-gap: 20px; }

/* Modal Select */
.field-container {
  width: 100%; }

.modal-select {
  width: 100%; }
  .modal-select-container {
    width: 100%; }
  .modal-select__menu {
    z-index: 20; }
    .modal-select__menu ::-webkit-scrollbar {
      width: 5px; }
    .modal-select__menu ::-webkit-scrollbar-thumb {
      background: #00000030;
      border-radius: 10px; }
  .modal-select__control {
    background: white !important;
    border: 1px solid #8891AA !important;
    border-radius: 8px !important;
    min-height: 38px !important;
    min-width: 140px;
    display: flex;
    align-content: center; }
  .modal-select__indicators {
    background: #F4F4F6;
    border-left: 1px solid #E1E3EA;
    border-radius: 0 8px 8px 0; }
  .modal-select__input-container, .modal-select__single-value, .modal-select__placeholder {
    padding: 0;
    font-family: SFProDisplay;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #20383D !important; }
  .modal-select__indicator-separator {
    display: none; }

.textarea .form-group {
  width: 100%; }

#modal-loader {
  width: 80vh;
  max-width: 1190px;
  height: 80vh;
  position: relative; }
  #modal-loader .loader {
    font-size: 80px; }

.pluginsModall {
  padding: 20px;
  position: relative;
  width: 800px;
  height: 560px;
  background: #fff; }

.settingsModall {
  padding: 30px;
  position: relative;
  width: 700px;
  background: #fff; }
  .settingsModall__text {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #052549;
    margin-bottom: 40px;
    text-align: center; }
  .settingsModall__list {
    width: 100%;
    max-height: 560px;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-gap: 24px;
    gap: 24px; }
  .settingsModall__item {
    display: flex;
    align-items: center;
    width: 305px;
    margin: 0 !important; }
  .settingsModall__link {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    background: #ffffff;
    border-radius: 4px;
    width: 100%;
    border: 1px solid #CACDD8; }
    .settingsModall__link p {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #052549; }
    .settingsModall__link img {
      width: 24px;
      height: 24px;
      margin-right: 16px; }
  .settingsModall__close {
    right: 12px;
    font-size: 18px;
    position: fixed;
    top: 12px; }
    .settingsModall__close svg {
      width: 16px;
      height: 16px; }
  .settingsModall__subtext {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    color: #052549;
    margin-bottom: 40px; }

.theme-list {
  grid-row-gap: 4px;
  row-gap: 4px; }
  .theme-list .col-3 {
    padding: 0;
    margin: 0; }

.theme-item {
  border-radius: 5px;
  overflow: hidden;
  border: 4px solid transparent;
  height: 148px;
  margin: 0; }
  .theme-item img {
    object-fit: cover;
    width: 185px;
    height: 140px; }

.theme-item:hover {
  border: 4px solid #117ae1; }

.form-error {
  color: var(--main-red);
  font-size: 12px;
  margin-top: 5px; }

.react-daterange-picker__calendar {
  width: 353.6px; }
  .react-daterange-picker__calendar .react-calendar {
    border-radius: 8px;
    border: 1px solid #dee1e6;
    overflow: hidden;
    padding: 8px;
    width: 360px !important; }
    .react-daterange-picker__calendar .react-calendar__navigation {
      margin-bottom: 5px; }
      .react-daterange-picker__calendar .react-calendar__navigation__arrow, .react-daterange-picker__calendar .react-calendar__navigation__label {
        position: relative;
        font-size: 20px;
        line-height: 1.6;
        font-weight: 600;
        color: #052549; }
        .react-daterange-picker__calendar .react-calendar__navigation__arrow:enabled:hover, .react-daterange-picker__calendar .react-calendar__navigation__arrow:enabled:focus, .react-daterange-picker__calendar .react-calendar__navigation__label:enabled:hover, .react-daterange-picker__calendar .react-calendar__navigation__label:enabled:focus {
          background-color: #F1F3F4; }
      .react-daterange-picker__calendar .react-calendar__navigation__label {
        font-size: 20px;
        font-weight: 600;
        color: #052549;
        border-radius: 12px; }
      .react-daterange-picker__calendar .react-calendar__navigation__arrow {
        border-radius: 50%;
        font-size: 20px;
        font-size: 0; }
      .react-daterange-picker__calendar .react-calendar__navigation .react-calendar__navigation__prev-button::before, .react-daterange-picker__calendar .react-calendar__navigation .react-calendar__navigation__prev2-button::before, .react-daterange-picker__calendar .react-calendar__navigation .react-calendar__navigation__next-button::before, .react-daterange-picker__calendar .react-calendar__navigation .react-calendar__navigation__next2-button::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .react-daterange-picker__calendar .react-calendar__navigation__prev-button::before {
        content: url(/static/media/calendar-prev.42fd1587.svg); }
      .react-daterange-picker__calendar .react-calendar__navigation__prev2-button::before {
        content: url(/static/media/calendar-prev2.13a08e59.svg); }
      .react-daterange-picker__calendar .react-calendar__navigation__next-button::before {
        content: url(/static/media/calendar-next.7935e1ea.svg); }
      .react-daterange-picker__calendar .react-calendar__navigation__next2-button::before {
        content: url(/static/media/calendar-next2.5823720f.svg); }
    .react-daterange-picker__calendar .react-calendar__month-view__weekdays__weekday {
      width: 48px;
      aspect-ratio: 1/1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px; }
      .react-daterange-picker__calendar .react-calendar__month-view__weekdays__weekday abbr {
        text-decoration: none; }
    .react-daterange-picker__calendar .react-calendar__tile {
      width: 32px;
      min-width: 32px;
      aspect-ratio: 1/1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      font-size: 18px;
      font-weight: 400; }
      .react-daterange-picker__calendar .react-calendar__tile:enabled:hover, .react-daterange-picker__calendar .react-calendar__tile:enabled:focus {
        background-color: #F1F3F4; }
      .react-daterange-picker__calendar .react-calendar__tile:enabled:active {
        background-color: #B3BAC3; }
    .react-daterange-picker__calendar .react-calendar__tile--now {
      background-color: var(--main-blue);
      color: #fff; }
      .react-daterange-picker__calendar .react-calendar__tile--now:enabled:hover, .react-daterange-picker__calendar .react-calendar__tile--now:enabled:focus {
        background-color: var(--main-blue); }
    .react-daterange-picker__calendar .react-calendar__tile--active {
      background-color: #E0EFFF;
      color: var(--main-blue);
      border-radius: unset; }
      .react-daterange-picker__calendar .react-calendar__tile--active:enabled:hover, .react-daterange-picker__calendar .react-calendar__tile--active:enabled:focus {
        background-color: #E0EFFF; }
    .react-daterange-picker__calendar .react-calendar__tile--rangeStart, .react-daterange-picker__calendar .react-calendar__tile--rangeEnd {
      background-color: var(--main-blue);
      color: #fff; }
      .react-daterange-picker__calendar .react-calendar__tile--rangeStart:enabled:hover, .react-daterange-picker__calendar .react-calendar__tile--rangeStart:enabled:focus, .react-daterange-picker__calendar .react-calendar__tile--rangeEnd:enabled:hover, .react-daterange-picker__calendar .react-calendar__tile--rangeEnd:enabled:focus {
        background-color: var(--main-blue); }
    .react-daterange-picker__calendar .react-calendar__tile--rangeStart {
      border-radius: 8px 0 0 8px; }
    .react-daterange-picker__calendar .react-calendar__tile--rangeEnd {
      border-radius: 0 8px 8px 0; }
    .react-daterange-picker__calendar .react-calendar__tile--rangeBothEnds {
      border-radius: 8px; }

.range-picker-wrapper {
  position: relative;
  max-width: 235px;
  width: 100%;
  height: 50px;
  padding: 14px 20px;
  z-index: 3;
  background-color: #ffffff;
  border: 1.5px solid #EAEDF0;
  border-radius: 40px; }
  .range-picker-wrapper .react-datepicker-wrapper {
    width: 162px;
    float: right; }

.date-picker-wrapper .react-datepicker-wrapper input {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #B3BAC3; }

.date-picker-wrapper .react-datepicker-wrapper input::placeholder {
  color: #B3BAC3; }

.date-picker-wrapper .react-datepicker__header {
  background-color: #ffffff;
  border-bottom: 1px solid #d0d9e2; }

.date-picker-wrapper .react-datepicker__triangle::after {
  display: none;
  border-bottom-color: #ffffff; }

.date-picker-wrapper .react-datepicker__triangle::before {
  display: none;
  border-bottom-color: transparent; }

.date-picker-wrapper .react-datepicker__day--keyboard-selected {
  background-color: var(--main-blue); }

.date-picker-wrapper .react-datepicker__day--selected {
  background-color: var(--main-blue); }

.date-picker-wrapper .react-datepicker__day--in-range {
  background-color: var(--main-blue);
  box-shadow: 0px 12px 50px 9px rgba(111, 116, 206, 0.1); }

.date-picker-wrapper .react-datepicker__current-month {
  color: #052549; }

.date-picker-wrapper .react-datepicker {
  border: none;
  box-shadow: 0px 12px 50px 9px rgba(18, 28, 255, 0.16); }

.react-select__control {
    box-shadow: none !important;
    border: 1.5px solid #8891AA !important;
}

.react-select__single-value {
    color: #808d9c !important;
}

.react-select__menu {
    color: #808d9c !important;
    border-radius: 10px !important;
    background: #fff !important;
    border: 1px solid transparent !important;
    margin: 4px 0 0 0 !important;
    z-index: 9 !important;
}

.react-select__menu::-webkit-scrollbar{
    width: 0;
    height: 0;
}

.react-select__indicators {
    background: #F4F4F6;
    border-radius: 0 4px 4px 0;
}


.react-select__option{
    padding: 12px 14px !important;
    border-radius: 4px;
    border: 1px solid  transparent;
}

.react-select__input{
    width: 90px !important;
    max-width: 90px;
}

.react-select__input-container{
    max-width: 90px;
}
.no-connection {
  pointer-events: none; }
  .no-connection.\--visible .no-connection__content {
    transform: translateY(0);
    opacity: 1; }
  .no-connection.\--visible .no-connection__overlay {
    opacity: .6; }
  .no-connection__content {
    position: fixed;
    top: 30px;
    width: 100%;
    max-width: 600px;
    left: 50%;
    margin-left: -300px;
    z-index: 1000;
    padding: 0 20px;
    background-color: #FAE8A9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    transition: .2s ease-in-out;
    transform: translateY(-100%); }
  .no-connection__overlay {
    background-color: #FFFFFF;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    content: "";
    z-index: 999;
    opacity: 0;
    transition: .3s ease-in-out; }
  .no-connection__text {
    color: #736125;
    font-size: 15px; }
  .no-connection svg {
    width: 40px;
    height: 40px; }
  .no-connection__icon {
    display: flex;
    align-items: center; }

.barchart * {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

.barchart path {
  transition: all .1s;
  position: relative;
  z-index: 10;
  filter: none; }

.barchart .apexcharts-tooltip {
  width: 0;
  height: 0;
  overflow: visible; }

#barchart-tooltip {
  position: -webkit-sticky;
  position: sticky;
  width: -webkit-max-content;
  width: max-content;
  padding: 10px 35px;
  transform: translate(-50%, -135%);
  background: #FFFFFF;
  border: 1px solid #E0EFFF;
  box-shadow: 0px 14px 20px rgba(0, 35, 74, 0.05); }
  #barchart-tooltip h4 {
    text-align: center;
    color: #052549;
    margin-bottom: 4px;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px; }
  #barchart-tooltip p {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    text-transform: uppercase;
    /* secondary */
    color: #B3BAC3; }
  #barchart-tooltip span {
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 18px;
    height: 18px;
    z-index: -1;
    transform: translate(-50%, 50%) rotate(45deg);
    background: #FFFFFF;
    border-bottom: 1px solid #E0EFFF;
    border-right: 1px solid #E0EFFF;
    box-shadow: 0px 14px 20px rgba(0, 35, 74, 0.05); }

.donut-chart {
  position: relative; }
  .donut-chart * {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }
  .donut-chart path {
    filter: none; }
  .donut-chart .chart-wrapper {
    position: relative;
    width: -webkit-max-content;
    width: max-content;
    margin: 0 auto; }
  .donut-chart .categories {
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 15px;
    row-gap: 15px;
    grid-column-gap: 45px;
    column-gap: 45px; }
    .donut-chart .categories .category {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      cursor: pointer;
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #B3BAC3; }
      .donut-chart .categories .category span {
        display: block;
        width: 8px;
        height: 8px;
        margin-right: 6px;
        border-radius: 50%;
        background: var(--main-blue);
        border: 1px solid #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 119, 255, 0.2); }
    .donut-chart .categories .active {
      color: var(--main-blue); }
  .donut-chart .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: 170px;
    height: 170px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    box-shadow: 0px 57px 30px rgba(0, 51, 108, 0.05); }
    .donut-chart .center h2 {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 600;
      font-size: 50px;
      line-height: 50px;
      color: #052549;
      margin-right: -5px; }
      .donut-chart .center h2 .percant {
        font-size: 20px;
        margin-left: -10px; }
    .donut-chart .center p {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #B3BAC3; }

.donut-thin-chart {
  position: relative; }
  .donut-thin-chart * {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }
  .donut-thin-chart path {
    filter: none; }
  .donut-thin-chart .chart-wrapper {
    position: relative;
    width: -webkit-max-content;
    width: max-content;
    margin: 0 auto; }
  .donut-thin-chart .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: calc(100% - 70px);
    height: calc(100% - 50px);
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    box-shadow: 0px 57px 30px rgba(0, 51, 108, 0.05); }
    .donut-thin-chart .center .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
      color: black; }
    .donut-thin-chart .center .subtitle {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.35); }
    .donut-thin-chart .center h2 {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 74px;
      color: #052549;
      margin-right: -5px; }
      .donut-thin-chart .center h2 .percant {
        font-size: 20px;
        margin-left: -10px; }
    .donut-thin-chart .center p {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #B3BAC3; }

.line-chart {
  padding: 24px; }

.line-chart-title {
  color: #2C3659;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  padding-left: 16px; }

.donut-chart {
  position: relative; }
  .donut-chart * {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }
  .donut-chart path {
    filter: none; }
  .donut-chart .chart-wrapper {
    position: relative;
    width: -webkit-max-content;
    width: max-content;
    margin: 0 auto; }
  .donut-chart .categories {
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 15px;
    row-gap: 15px;
    grid-column-gap: 45px;
    column-gap: 45px; }
    .donut-chart .categories .category {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      cursor: pointer;
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #B3BAC3; }
      .donut-chart .categories .category span {
        display: block;
        width: 8px;
        height: 8px;
        margin-right: 6px;
        border-radius: 50%;
        background: var(--main-blue);
        border: 1px solid #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 119, 255, 0.2); }
    .donut-chart .categories .active {
      color: var(--main-blue); }
  .donut-chart .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: 170px;
    height: 170px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    box-shadow: 0px 57px 30px rgba(0, 51, 108, 0.05); }
    .donut-chart .center h2 {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 600;
      font-size: 50px;
      line-height: 50px;
      color: #052549;
      margin-right: -5px; }
      .donut-chart .center h2 .percant {
        font-size: 20px;
        margin-left: -10px; }
    .donut-chart .center p {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #B3BAC3; }

.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh; }

.loader {
  color: #0077ffc2;
  font-size: 60px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  transform: translateZ(0);
  animation: load6 1.7s infinite ease, round 1.7s infinite ease; }

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em; }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em; }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em; }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; } }

@keyframes round {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.billing-error {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }
  .billing-error__inner {
    width: 800px;
    padding-block: 80px 40px;
    padding-inline: 60px;
    background: #FFFFFF;
    box-shadow: 0 0 50px -5px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .billing-error__title {
    font-weight: 600;
    font-size: 36px;
    color: #052549;
    margin-bottom: 35px;
    line-height: 120%;
    text-align: center; }
  .billing-error__subtitle {
    color: #B3BAC3;
    margin-bottom: 80px; }
    .billing-error__subtitle h3 {
      margin-bottom: 35px;
      font-weight: 400;
      font-size: 26px;
      line-height: 120%; }
      .billing-error__subtitle h3:first-letter {
        margin-left: 1em; }
    .billing-error__subtitle ul li {
      font-weight: 400;
      list-style: inside;
      font-size: 20px;
      line-height: 120%;
      margin-bottom: 10px; }
  .billing-error__close {
    border-radius: 60px;
    background-color: var(--main-blue);
    width: 250px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #FFFFFF; }

.notification__trigger {
  position: relative; }

.notification__badge {
  font-size: 12px;
  width: 17px;
  height: 17px;
  color: #fff;
  font-weight: 500;
  position: absolute;
  top: -5px;
  right: -7px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d60000; }

.notification__card {
  position: relative;
  min-width: 300px;
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #052549; }

.notification__delete {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 100%;
  background-color: rgba(0, 119, 255, 0.1); }

.notification__delete svg {
  width: 10px;
  height: 10px; }

.notification__card:not(:first-child) {
  margin-top: 15px; }

.notification__info {
  width: 80%; }

.notification__user {
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 0 0; }

.notification__phone {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px; }

.notification__description {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  margin-top: 10px; }

.plugins {
  font-family: 'SF Pro Display';
  margin: 50px 50px 30px 50px;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(0, 51, 108, 0.05);
  border-radius: 6px;
  height: calc(100vh - 160px);
  overflow: hidden;
  position: relative; }
  .plugins-title {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #052549;
    padding: 30px;
    cursor: default;
    border-bottom: 2px solid #F1F3F4; }
  .plugins__content {
    display: flex;
    height: calc(100% - 90px); }
  .plugins__info {
    width: 290px;
    padding: 20px;
    border-right: 2px solid #F1F3F4;
    height: 100%; }
  .plugins__info-text {
    margin: 20px 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #052549; }
  .plugins__info-card {
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #385999;
    border: 1.5px solid #0077FF;
    box-shadow: 0px 3px 6px rgba(0, 51, 108, 0.05);
    border-radius: 6px;
    padding: 4px 0 15px 0px;
    margin-bottom: 20px; }
  .plugins__info-btn {
    font-family: 'SF Pro Display';
    padding: 4px 37px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 6px; }
  .plugins__info-subcard {
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #F6F6F6;
    box-shadow: 0px 3px 6px rgba(0, 51, 108, 0.05);
    border-radius: 6px;
    padding: 4px 0 15px 0px; }
  .plugins__cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: calc(100% - 40px);
    margin: 20px 19px 20px 20px;
    grid-gap: 20px;
    gap: 20px;
    overflow-y: scroll;
    z-index: 1; }
  .plugins__content-card {
    width: 250px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #F6F6F6;
    box-shadow: 0px 3px 6px rgba(0, 51, 108, 0.05);
    border-radius: 6px;
    padding: 4px 0 15px 0px; }
  .plugins__call {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2; }

.app__task-log-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px; }

.app__task-log-title {
  font-size: 24px;
  color: #052549;
  margin: 0; }

.app-view {
  flex-wrap: nowrap;
  font-family: "SF Pro Display";
  display: flex;
  height: calc(100vh - 120px);
  width: 100vw; }
  .app-view__navbar {
    display: flex;
    padding: 10px 40px 0;
    border-bottom: 1px solid #ccc; }
    .app-view__navbar .navbar-item {
      padding: 10px 20px;
      color: rgba(0, 0, 0, 0.4);
      cursor: pointer; }
    .app-view__navbar .navbar-item.active {
      font-weight: bold;
      color: black;
      border-bottom: 2px solid black; }
    .app-view__navbar .app-view__content {
      margin-top: 20px; }
  .app-view__info {
    display: flex;
    flex-direction: column;
    max-width: 480px !important;
    padding: 0 20px 0 0; }
    .app-view__info .info-inner-wrapper {
      background: #ffffff;
      box-shadow: 0 4px 6px rgba(0, 51, 108, 0.05);
      border-radius: 12px;
      overflow: scroll;
      height: 100%; }
  .app-view__info-application {
    width: 100%;
    padding: 20px; }
  .app-view__info-back {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    color: #000;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: left; }
  .app-view__info-image {
    width: 100px;
    height: 100px; }
  .app-view__info-name {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: left; }
  .app-view__info-type {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: rgba(0, 0, 0, 0.35); }
  .app-view .info-call {
    width: 355px;
    padding: 10px 0; }
  .app-view .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .app-view .info-call__name {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    color: rgba(0, 0, 0, 0.5); }
  .app-view__info-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    background: #0bcf74;
    padding: 11px 20px;
    border-radius: 8px; }
  .app-view__info-img {
    width: 18px;
    height: 18px; }
  .app-view__info-id {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 10px; }
  .app-view__info-unsorted {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 5px;
    color: #ffffff; }
    .app-view__info-unsorted span {
      color: rgba(255, 255, 255, 0.6); }
  .app-view__info-lines {
    width: 100%;
    height: 5px;
    align-items: center; }
  .app-view__info-fline {
    width: 80px;
    height: 100%;
    background: #f1f3f4; }
  .app-view__info-lline {
    flex: 1 1;
    height: 100%;
    background: #f1f3f44d; }
  .app-view__info-content {
    height: 100%; }
  .app-view__info-user {
    padding: 0 12px 20px 20px; }
  .app-view__info-contact {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    grid-gap: 20px;
    gap: 20px; }
  .app-view__info-card {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    color: rgba(0, 0, 0, 0.35); }
    .app-view__info-card .image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: #ececec;
      border-radius: 50%; }
    .app-view__info-card img {
      width: 35%; }
  .app-view__info-item {
    margin-top: 10px;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #f1f3f4;
    box-shadow: 0px 3px 6px rgba(0, 51, 108, 0.05);
    border-radius: 6px; }
  .app-view__info-subtext {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin: 0 16px 0px 0;
    text-align: left; }
  .app-view__info-userName {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #0077ff; }
  .app-view__info-item-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #0077ff; }
  .app-view__info-item-text:not(:last-child) {
    margin-bottom: 6px; }
  .app-view__info-item-subtext {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #b3bac3;
    margin-right: 20px; }
  .app-view__info-item-subtext:not(:last-child) {
    margin-bottom: 8px; }
  .app-view__info-item-top {
    margin-bottom: 10px; }
    .app-view__info-item-top p {
      margin: 0; }
  .app-view__content {
    position: relative;
    background: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 51, 108, 0.05);
    border-radius: 12px;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1;
    grid-gap: 10px;
    gap: 10px;
    overflow-x: hidden;
    overflow-y: scroll; }
  .app-view__content-main {
    height: 100%;
    overflow-y: scroll; }
  .app-view__content-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #2c3659; }
  .app-view__content-item {
    display: flex;
    padding: 15px;
    background: #ffffff;
    border-bottom: 2px solid #f1f3f4;
    margin: 10px 0; }
  .app-view__content-item-main {
    grid-gap: 100px;
    gap: 100px; }
  .app-view__content-item-text {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #2c3659; }
  .app-view__content-item-date {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #a7aec1;
    padding-top: 4px; }
  .app-view__call-status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: #eff2f3;
    border-radius: 50%; }
  .app-view__content-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    color: #2C3659;
    font-size: 14px;
    font-weight: 600;
    background: #E2E4EA;
    width: 140px;
    height: 40px;
    border-radius: 8px; }
  .app-view__content-img {
    width: 20px;
    height: 20px; }
  .app-view .app-view__content-bl {
    color: #0077ff; }
  .app-view__content-bold {
    color: #000; }
  .app-view__content-area {
    padding: 0 20px;
    width: 100%;
    grid-gap: 15px;
    gap: 15px;
    font-size: 16px; }

.application-card__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  grid-gap: 10px;
  gap: 10px; }

.application-card {
  width: calc(100% - 30px);
  margin: 0 20px;
  padding: 20px;
  border-radius: 12px;
  background: #FFA92714; }
  .application-card__id {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left; }
  .application-card__operator {
    padding: 5px 15px;
    background: #ffe0e0;
    border-radius: 16px;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: red; }
  .application-card__info {
    margin-top: 10px;
    grid-gap: 50px;
    gap: 50px; }
    .application-card__info-name {
      color: rgba(0, 0, 0, 0.4);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left; }
    .application-card__info-data {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left; }
  .application-card__audio-player {
    background: rgba(0, 14, 143, 0.05);
    height: 60px;
    width: 100%;
    border-radius: 12px; }
  .application-card__status {
    display: flex;
    width: 100%;
    margin-top: 30px;
    border-radius: 0;
    justify-content: center; }
    .application-card__status .status-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 25%;
      color: #fff;
      background: rgba(14, 89, 230, 0.9);
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      padding: 10px 20px;
      -webkit-clip-path: polygon(95% 0%, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);
              clip-path: polygon(95% 0%, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);
      border-radius: 0;
      margin-left: -10px; }
      .application-card__status .status-item:last-child {
        background: #ffa927; }
        .application-card__status .status-item:last-child::after {
          border-left: 15px solid #ffa927; }
      .application-card__status .status-item__name {
        font-size: 16px;
        font-weight: bold; }
      .application-card__status .status-item__date {
        font-size: 14px;
        margin-top: 5px; }

.app-view__buttons {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  position: relative;
  width: 100%;
  margin: 15px 0; }

.file-btn {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; }

.add-notification {
  top: 50%;
  right: 0;
  background-color: var(--main-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px; }
  .add-notification svg path {
    fill: #fff; }

.submit-btn {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-left: auto; }

.task-log {
  position: absolute;
  bottom: calc(50vh - 390px);
  left: 50px;
  z-index: 100;
  transform: translateY(-50%) scale(0);
  opacity: 0;
  pointer-events: none;
  padding: 20px;
  border-radius: 15px;
  background-color: #fff;
  transition: 0.3s ease-in-out;
  max-width: 568px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5); }
  .task-log_opened {
    transform: translateY(-20%) scale(1);
    opacity: 1;
    pointer-events: initial; }

.customer-info {
  padding: 50px; }
  .customer-info .info-title {
    font-size: 22px;
    font-weight: 700;
    line-height: 30.05px;
    text-align: left; }
  .customer-info .info-card {
    margin-top: 30px;
    grid-gap: 30px 0;
    gap: 30px 0; }
  .customer-info .info-wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px; }
    .customer-info .info-wrapper__name {
      font-size: 15px;
      font-weight: 500;
      line-height: 20.49px;
      color: rgba(0, 0, 0, 0.4); }
    .customer-info .info-wrapper__data {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px; }

.application-card-view-flex {
  height: 100vh; }

.application-card__wrapper {
  margin: 10px 0; }
  .application-card__wrapper .chat-message-wrapper {
    padding: 5px 20px; }
    .application-card__wrapper .chat-message-wrapper .message-owner {
      color: black;
      font-weight: 500;
      font-size: 14px; }
    .application-card__wrapper .chat-message-wrapper .chat-message {
      padding: 20px 22px;
      background: #F8FFEE;
      border-radius: 0 10px 10px 10px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      min-width: 280px;
      font-size: 16px;
      line-height: 24px;
      margin-top: 12px;
      border: 1px solid #0000001A; }
      .application-card__wrapper .chat-message-wrapper .chat-message .created-at {
        color: #000000;
        font-size: 14px; }
      .application-card__wrapper .chat-message-wrapper .chat-message .download-file {
        background: #ECF1FF;
        border-radius: 50px;
        padding: 9px;
        width: 38px;
        height: 38px; }

@font-face {
    font-family: SF Pro Display;
    src: url(/static/media/SF-Pro-Display-Light.fd8ab6b3.woff2) format("woff2"), url(/static/media/SF-Pro-Display-Light.e18df41a.woff) format("woff");
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: SF Pro Display;
    src: url(/static/media/SF-Pro-Display-Regular.ecc97c84.woff2) format("woff2"), url(/static/media/SF-Pro-Display-Regular.21b9479b.woff) format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: SF Pro Display;
    src: url(/static/media/SF-Pro-Display-Medium.320f118c.woff2) format("woff2"), url(/static/media/SF-Pro-Display-Medium.26a1e30c.woff) format("woff");
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: SF Pro Display;
    src: url(/static/media/SF-Pro-Display-Semi-Bold.af64ab08.woff2) format("woff2"), url(/static/media/SF-Pro-Display-Semi-Bold.2c151db5.woff) format("woff");
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: SF Pro Display;
    src: url(/static/media/SF-Pro-Display-Bold.b5ba3dd8.woff2) format("woff2"), url(/static/media/SF-Pro-Display-Bold.43088ea6.woff) format("woff");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: Georgia;
    src: url(/static/media/Georgia-Regular.f4b306ee.ttf);
    font-weight: 400
}

@font-face {
    font-family: Georgia-italic;
    src: url(/static/media/Georgia-Italic.f1650f82.ttf);
    font-weight: 400
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem); }

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x)); }

.row > * {
  flex-shrink: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  margin-top: var(--bs-gutter-y);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5); }

.col {
  flex: 1 0; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333333%; }

.offset-2 {
  margin-left: 16.66666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333333%; }

.offset-5 {
  margin-left: 41.66666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333333%; }

.offset-8 {
  margin-left: 66.66666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333333%; }

.offset-11 {
  margin-left: 91.66666667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

.g-6,
.gx-6 {
  --bs-gutter-x: 6rem; }

.g-6,
.gy-6 {
  --bs-gutter-y: 6rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333333%; }
  .offset-sm-2 {
    margin-left: 16.66666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333333%; }
  .offset-sm-5 {
    margin-left: 41.66666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333333%; }
  .offset-sm-8 {
    margin-left: 66.66666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333333%; }
  .offset-sm-11 {
    margin-left: 91.66666667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333333%; }
  .offset-md-2 {
    margin-left: 16.66666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333333%; }
  .offset-md-5 {
    margin-left: 41.66666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333333%; }
  .offset-md-8 {
    margin-left: 66.66666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333333%; }
  .offset-md-11 {
    margin-left: 91.66666667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333333%; }
  .offset-lg-2 {
    margin-left: 16.66666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333333%; }
  .offset-lg-5 {
    margin-left: 41.66666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333333%; }
  .offset-lg-8 {
    margin-left: 66.66666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333333%; }
  .offset-lg-11 {
    margin-left: 91.66666667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333333%; }
  .offset-xl-2 {
    margin-left: 16.66666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333333%; }
  .offset-xl-5 {
    margin-left: 41.66666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333333%; }
  .offset-xl-8 {
    margin-left: 66.66666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333333%; }
  .offset-xl-11 {
    margin-left: 91.66666667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333333%; }
  .offset-xxl-2 {
    margin-left: 16.66666667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333333%; }
  .offset-xxl-5 {
    margin-left: 41.66666667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333333%; }
  .offset-xxl-8 {
    margin-left: 66.66666667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333333%; }
  .offset-xxl-11 {
    margin-left: 91.66666667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; } }

@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; } }

@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; } }

@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; } }

@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

:root {
  --main-blue: #0263E0;
  --main-red: #D61F1F;
  --main-green: #07c352; }

* {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: #00000030 #fff;
  scrollbar-track-color: #00000030;
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  *::-webkit-scrollbar {
    width: 10px;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out; }
  *::-webkit-scrollbar-track {
    background: #fff;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out; }
  *::-webkit-scrollbar-thumb {
    background: #00000030;
    border-radius: 20px;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out; }
  *::-webkit-scrollbar-thumb:hover {
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out; }

* {
  box-sizing: border-box;
  outline: none;
  padding: 0;
  margin: 0; }
  *:hover {
    text-decoration: none; }

body {
  overflow-x: hidden !important; }

p {
  margin: 0; }

ul {
  padding: 0;
  margin: 0; }
  ul li {
    list-style: none; }
    ul li a {
      text-decoration: none; }

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none; }
  button:focus {
    outline: none;
    box-shadow: none; }

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none; }

a {
  color: #004098;
  text-decoration: none;
  cursor: pointer; }
  a:hover {
    text-decoration: none; }

input,
textarea {
  border: none;
  outline: none;
  width: 100%;
  box-shadow: none; }

textarea {
  resize: none; }

label {
  padding: 0;
  margin: 0; }

.m-b-0 {
  margin-bottom: 0px; }

.m-t-0 {
  margin-top: 0px; }

.m-l-0 {
  margin-left: 0px; }

.m-r-0 {
  margin-right: 0px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-t-5 {
  margin-top: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-t-10 {
  margin-top: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-t-15 {
  margin-top: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-t-20 {
  margin-top: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.m-b-25 {
  margin-bottom: 25px; }

.m-t-25 {
  margin-top: 25px; }

.m-l-25 {
  margin-left: 25px; }

.m-r-25 {
  margin-right: 25px; }

.m-b-30 {
  margin-bottom: 30px; }

.m-t-30 {
  margin-top: 30px; }

.m-l-30 {
  margin-left: 30px; }

.m-r-30 {
  margin-right: 30px; }

.m-b-35 {
  margin-bottom: 35px; }

.m-t-35 {
  margin-top: 35px; }

.m-l-35 {
  margin-left: 35px; }

.m-r-35 {
  margin-right: 35px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-t-40 {
  margin-top: 40px; }

.m-l-40 {
  margin-left: 40px; }

.m-r-40 {
  margin-right: 40px; }

.m-b-45 {
  margin-bottom: 45px; }

.m-t-45 {
  margin-top: 45px; }

.m-l-45 {
  margin-left: 45px; }

.m-r-45 {
  margin-right: 45px; }

.m-b-50 {
  margin-bottom: 50px; }

.m-t-50 {
  margin-top: 50px; }

.m-l-50 {
  margin-left: 50px; }

.m-r-50 {
  margin-right: 50px; }

.m-b-55 {
  margin-bottom: 55px; }

.m-t-55 {
  margin-top: 55px; }

.m-l-55 {
  margin-left: 55px; }

.m-r-55 {
  margin-right: 55px; }

.m-b-60 {
  margin-bottom: 60px; }

.m-t-60 {
  margin-top: 60px; }

.m-l-60 {
  margin-left: 60px; }

.m-r-60 {
  margin-right: 60px; }

.m-b-65 {
  margin-bottom: 65px; }

.m-t-65 {
  margin-top: 65px; }

.m-l-65 {
  margin-left: 65px; }

.m-r-65 {
  margin-right: 65px; }

.m-b-70 {
  margin-bottom: 70px; }

.m-t-70 {
  margin-top: 70px; }

.m-l-70 {
  margin-left: 70px; }

.m-r-70 {
  margin-right: 70px; }

.m-b-75 {
  margin-bottom: 75px; }

.m-t-75 {
  margin-top: 75px; }

.m-l-75 {
  margin-left: 75px; }

.m-r-75 {
  margin-right: 75px; }

.m-b-80 {
  margin-bottom: 80px; }

.m-t-80 {
  margin-top: 80px; }

.m-l-80 {
  margin-left: 80px; }

.m-r-80 {
  margin-right: 80px; }

.m-b-85 {
  margin-bottom: 85px; }

.m-t-85 {
  margin-top: 85px; }

.m-l-85 {
  margin-left: 85px; }

.m-r-85 {
  margin-right: 85px; }

.m-b-90 {
  margin-bottom: 90px; }

.m-t-90 {
  margin-top: 90px; }

.m-l-90 {
  margin-left: 90px; }

.m-r-90 {
  margin-right: 90px; }

.m-b-95 {
  margin-bottom: 95px; }

.m-t-95 {
  margin-top: 95px; }

.m-l-95 {
  margin-left: 95px; }

.m-r-95 {
  margin-right: 95px; }

.m-b-100 {
  margin-bottom: 100px; }

.m-t-100 {
  margin-top: 100px; }

.m-l-100 {
  margin-left: 100px; }

.m-r-100 {
  margin-right: 100px; }

.p-b-0 {
  padding-bottom: 0px; }

.p-t-0 {
  padding-top: 0px; }

.p-l-0 {
  padding-left: 0px; }

.p-r-0 {
  padding-right: 0px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-t-5 {
  padding-top: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-t-10 {
  padding-top: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-t-15 {
  padding-top: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-t-20 {
  padding-top: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.p-b-25 {
  padding-bottom: 25px; }

.p-t-25 {
  padding-top: 25px; }

.p-l-25 {
  padding-left: 25px; }

.p-r-25 {
  padding-right: 25px; }

.p-b-30 {
  padding-bottom: 30px; }

.p-t-30 {
  padding-top: 30px; }

.p-l-30 {
  padding-left: 30px; }

.p-r-30 {
  padding-right: 30px; }

.p-b-35 {
  padding-bottom: 35px; }

.p-t-35 {
  padding-top: 35px; }

.p-l-35 {
  padding-left: 35px; }

.p-r-35 {
  padding-right: 35px; }

.p-b-40 {
  padding-bottom: 40px; }

.p-t-40 {
  padding-top: 40px; }

.p-l-40 {
  padding-left: 40px; }

.p-r-40 {
  padding-right: 40px; }

.p-b-45 {
  padding-bottom: 45px; }

.p-t-45 {
  padding-top: 45px; }

.p-l-45 {
  padding-left: 45px; }

.p-r-45 {
  padding-right: 45px; }

.p-b-50 {
  padding-bottom: 50px; }

.p-t-50 {
  padding-top: 50px; }

.p-l-50 {
  padding-left: 50px; }

.p-r-50 {
  padding-right: 50px; }

.p-b-55 {
  padding-bottom: 55px; }

.p-t-55 {
  padding-top: 55px; }

.p-l-55 {
  padding-left: 55px; }

.p-r-55 {
  padding-right: 55px; }

.p-b-60 {
  padding-bottom: 60px; }

.p-t-60 {
  padding-top: 60px; }

.p-l-60 {
  padding-left: 60px; }

.p-r-60 {
  padding-right: 60px; }

.p-b-65 {
  padding-bottom: 65px; }

.p-t-65 {
  padding-top: 65px; }

.p-l-65 {
  padding-left: 65px; }

.p-r-65 {
  padding-right: 65px; }

.p-b-70 {
  padding-bottom: 70px; }

.p-t-70 {
  padding-top: 70px; }

.p-l-70 {
  padding-left: 70px; }

.p-r-70 {
  padding-right: 70px; }

.p-b-75 {
  padding-bottom: 75px; }

.p-t-75 {
  padding-top: 75px; }

.p-l-75 {
  padding-left: 75px; }

.p-r-75 {
  padding-right: 75px; }

.p-b-80 {
  padding-bottom: 80px; }

.p-t-80 {
  padding-top: 80px; }

.p-l-80 {
  padding-left: 80px; }

.p-r-80 {
  padding-right: 80px; }

.p-b-85 {
  padding-bottom: 85px; }

.p-t-85 {
  padding-top: 85px; }

.p-l-85 {
  padding-left: 85px; }

.p-r-85 {
  padding-right: 85px; }

.p-b-90 {
  padding-bottom: 90px; }

.p-t-90 {
  padding-top: 90px; }

.p-l-90 {
  padding-left: 90px; }

.p-r-90 {
  padding-right: 90px; }

.p-b-95 {
  padding-bottom: 95px; }

.p-t-95 {
  padding-top: 95px; }

.p-l-95 {
  padding-left: 95px; }

.p-r-95 {
  padding-right: 95px; }

.p-b-100 {
  padding-bottom: 100px; }

.p-t-100 {
  padding-top: 100px; }

.p-l-100 {
  padding-left: 100px; }

.p-r-100 {
  padding-right: 100px; }

.bg_white {
  background-color: #fff; }

.container {
  max-width: 1170px;
  --bs-gutter-x: 15px; }

.row {
  --bs-gutter-x: 8px; }
  .row .centered-col {
    margin-left: auto;
    margin-right: auto; }
  @media (max-width: 767px) {
    .row {
      --bs-gutter-x: 15px; }
      .row:not(.no-mb) {
        margin-bottom: -15px; }
        .row:not(.no-mb) > * {
          margin-bottom: 15px; } }

.site-wrapper {
  min-height: calc(100vh - 80px);
  min-width: 355px;
  display: flex;
  font-family: "SFProDisplay";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  margin-top: 60px;
  margin-left: 102px; }
  @media (max-width: 1500px) {
    .site-wrapper {
      margin-left: 100px; } }
  @media (max-width: 992px) {
    .site-wrapper {
      margin-left: 100px; } }

body {
  letter-spacing: 0;
  font-family: "SFProDisplay";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: white;
  background: #eaedf0;
  overflow-x: hidden; }

label {
  cursor: pointer; }

.btn {
  background: var(--main-blue);
  border-radius: 60px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  padding: 14px 28px;
  min-width: 141px;
  transition: all 0.3s ease-in-out; }
  .btn:hover {
    color: #fff;
    box-shadow: 0px 8px 14px rgba(0, 119, 255, 0.25); }

#myChart {
  width: 100%;
  height: 300px; }

#myChart2 {
  width: 240px; }

#myChart3 {
  width: 240px; }

.chartWrapper2 {
  width: 350px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px auto;
  padding-bottom: 30px; }
  .chartWrapper2:last-child {
    margin: 0 auto; }

.modal {
  overflow: scroll;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px); }
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
    height: -webkit-max-content;
    height: max-content;
    overflow: auto;
    overflow-x: hidden; }
    .modal-content.no-overflow {
      overflow: unset; }
  .modal .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    max-height: 60px; }
    .modal .modal-header .call-history {
      width: calc(60px - 2rem);
      height: calc(60px - 2rem);
      cursor: pointer; }
      .modal .modal-header .call-history img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .modal-dialog {
    max-width: 1190px; }
  .modal-body {
    max-height: 700px;
    padding: 10px 30px;
    padding-bottom: 20px; }
    .modal-body .history-container {
      position: absolute;
      transition: padding 0ms 300ms ease-in-out, max-width 300ms ease-in-out;
      max-width: 0;
      height: 0;
      overflow: hidden;
      top: 70px;
      right: 0;
      padding: unset;
      transition: all 0.3s; }
      .modal-body .history-container.active {
        max-width: 1070px;
        height: 100%;
        padding-right: 30px; }
        .modal-body .history-container.active .main-card {
          height: 100%; }
  .modal-footer {
    display: flex;
    justify-content: flex-start;
    grid-gap: 23px;
    gap: 23px; }
  .modal-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #20383D; }
  .modal-btn {
    color: #fff;
    padding: 14px 28px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    transition: 0.3s;
    border-radius: 32px; }
    .modal-btn.blue {
      background: var(--main-blue); }
      .modal-btn.blue:hover {
        background-color: #006ae4; }
    .modal-btn.red {
      background: var(--main-red); }
      .modal-btn.red:hover {
        background-color: #df3d3d; }
    .modal-btn:active {
      background-color: #fff;
      box-shadow: 0 2px #666;
      transform: translateY(1px); }

.caller-type {
  position: absolute;
  width: -webkit-max-content;
  width: max-content;
  background: #f1f3f4;
  border: 3px solid #f1f3f4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px; }
  .caller-type .caller-label {
    padding: 15px 20px;
    margin: 0;
    text-align: center; }
  .caller-type .active {
    border-radius: 30px;
    background: #fff; }

.caller-number {
  font-weight: 700;
  font-size: 20px;
  line-height: 55px;
  color: #20383D;
  display: flex;
  align-items: center;
  position: absolute;
  left: 30px; }

.caller-inner {
  display: flex;
  justify-content: space-between; }

.caller-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 18px;
  color: #20383D; }

.caller-border {
  border: 2px solid #f1f3f4;
  padding: 15px !important; }

.caller-shadow {
  box-shadow: none; }

.caller-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #20383D;
  margin-bottom: 8px;
  width: 100%; }

.caller-control {
  background: white;
  border: 1px solid #8891AA;
  border-radius: 8px;
  width: 100%;
  height: 38px;
  padding: 15px;
  color: #20383D; }

.caller-textarea {
  width: 100%;
  padding: 20px;
  resize: none;
  outline: none;
  background: #ffffff;
  border: 1px solid #8891AA;
  border-radius: 8px;
  min-height: 100px;
  color: #20383D; }

.caller-textarea::placeholder {
  color: #58747A; }

.caller .input__wrapper {
  align-items: flex-start; }

.caller .input__wrapper
.select2-container.select2-container--default
.select2-selection {
  background: #ffffff;
  border: 1px solid #f1f3f4;
  border-radius: 4px;
  padding: 30px 15px;
  height: 61px; }

.caller-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px; }

.history {
  max-height: 480px;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding: 0 5px; }
  .history::-webkit-scrollbar {
    width: 5px; }
  .history::-webkit-scrollbar-thumb {
    border-radius: 20px; }
  .history::-webkit-scrollbar-thumb, .history::-webkit-scrollbar-thumb:hover {
    background-color: #00000030;
    -webkit-transition: 4s ease-in-out;
    transition: 4s ease-in-out; }
  .history-operator {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background: #ffffff;
    border: 1px solid #a0a0a0;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000; }

.modal-backdrop.show {
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px); }

.mb-0 {
  margin-bottom: 0 !important; }

.content-table__call svg {
  width: 24px;
  height: 24px; }

.content-table__call path {
  fill: #4976ce;
  stroke: #4976ce; }

.content-table tbody tr:hover {
  background: #EBF4FF; }

.content-table tbody tr {
  outline-color: var(--main-blue);
  outline-offset: -1px;
  transition: none; }

.table-select {
  width: 100%; }
  .table-select__menu {
    z-index: 200; }
    .table-select__menu ::-webkit-scrollbar {
      width: 5px; }
    .table-select__menu ::-webkit-scrollbar-thumb {
      background-color: #00000030;
      border-radius: 5px; }

.table-select__control {
  background: white !important;
  border: 1px solid transparent !important;
  border-radius: 8px !important;
  min-height: 44px !important;
  min-width: 140px; }

.table-select__input-container,
.table-select__single-value,
.table-select__placeholder {
  padding: 0;
  font-family: SFProDisplay;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #20383D !important; }

.audio {
  position: absolute;
  visibility: hidden;
  pointer-events: none; }
  .audio_active {
    transform: translateY(0); }

.caller-label {
  margin-bottom: 20px;
  display: block; }

.cursor_pointer {
  cursor: pointer; }

.service-tooltip {
  position: absolute;
  bottom: calc(100% + 20px);
  left: 50%;
  z-index: 10;
  transform: translate(-50%, 10px);
  background: #ffffff;
  border: 1px solid #b3bac3;
  box-shadow: 0 3px 6px rgba(0, 51, 108, 0.2);
  border-radius: 6px;
  padding: 10px;
  transition: 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0; }
  .service-tooltip_active {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0); }
  .service-tooltip__item {
    font-size: 14px;
    color: #20383D;
    white-space: nowrap; }
  .service-tooltip__item:not(:last-child) {
    margin-bottom: 5px; }
  .service-tooltip-wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #f1f3f4;
    font-weight: 500;
    font-size: 14px;
    color: #b3bac3;
    position: relative;
    cursor: pointer; }
    .service-tooltip-wrapper img {
      position: absolute;
      top: 0;
      right: -3px; }

.modal-select_menu {
  z-index: 2; }

.modal-select_menuPortal::-webkit-scrollbar {
  display: none; }

.Toastify__toast-theme--light {
  background: rgba(0, 0, 0, 0.6) !important;
  color: white !important; }

.Toastify__close-button {
  color: white !important;
  opacity: 0.4 !important; }

.Toastify__progress-bar.Toastify__progress-bar--error {
  background-color: var(--main-red); }

.Toastify__toast-icon {
  --toastify-icon-color-error: var(--main-red); }

.form-input {
  color: inherit; }

.customer-table {
  padding: 0; }
  .customer-table .content-table {
    padding: 30px; }

.operator-update-control {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .operator-update-control button:first-child {
    margin-right: 20px; }

.update-modal .row {
  width: 100%; }

.caller-control input {
  color: inherit; }

.analytics .main-bottom {
  overflow: initial; }
  .analytics .main-bottom.bottom {
    display: flex; }
    @media screen and (max-width: 880px) {
      .analytics .main-bottom.bottom {
        flex-direction: column;
        grid-gap: 60px;
        gap: 60px; } }
    .analytics .main-bottom.bottom .main-content {
      margin-bottom: 0;
      flex: 1 1; }
      .analytics .main-bottom.bottom .main-content .donut-chart {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px; }

.table-td-blue tbody td {
  color: #052549; }

.import-btn_blue {
  display: flex;
  align-items: center;
  font-weight: 500; }

.import-btn_blue svg {
  margin-right: 10px; }

.import-btn_blue svg path {
  fill: #fff; }

@font-face {
  font-family: "SFProDisplay";
  src: url(/static/media/SF-Pro-Display-Regular.21b9479b.woff) format("woff"), url(/static/media/SF-Pro-Display-Regular.ecc97c84.woff2) format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "SFProDisplay";
  src: url(/static/media/SF-Pro-Display-Medium.26a1e30c.woff) format("woff"), url(/static/media/SFProDisplay-Medium.657e9863.woff2) format("woff2");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "SFProDisplay";
  src: url(/static/media/SF-Pro-Display-Bold.43088ea6.woff) format("woff"), url(/static/media/SF-Pro-Display-Semi-Bold.af64ab08.woff2) format("woff2");
  font-weight: 600;
  font-style: normal; }

.header {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 100px);
  padding: 5px 20px;
  z-index: 100; }
  @media (max-width: 1500px) {
    .header {
      width: calc(100% - 80px); } }
  @media (max-width: 992px) {
    .header {
      width: calc(100% - 100px); } }
  .header-inner {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .header__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .header__actions > :not(:last-child) {
    margin-right: 25px; }
  .header-logo {
    display: block;
    flex: 0 0 160px; }
    .header-logo svg {
      width: 100%;
      height: 100%; }
    .header-logo.main {
      display: none; }
  .header-list {
    display: flex;
    align-items: center;
    flex: 1 1;
    justify-content: space-evenly;
    max-width: 800px; }
  .header-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: white;
    transition: all 0.2s linear;
    position: relative; }
    .header-link::after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 0;
      width: 0;
      height: 3px;
      background: var(--main-blue);
      border-radius: 3px;
      transition: 0.3s ease-in-out; }
    .header-link.active {
      color: white; }
      .header-link.active::after {
        width: 100%; }
    .header-link:hover {
      color: #bfbfbf; }
  .header-burger {
    display: none; }
  .header-nav {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: space-around; }
    .header-nav ul li {
      white-space: nowrap; }
  .header-form {
    display: flex;
    align-items: center;
    margin: 0 -10px; }
  .header-mob {
    display: none; }
  .header-label {
    display: flex;
    align-items: center;
    margin: 0 10px; }
    .header-label__title {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #b3bac3;
      margin-right: 15px; }
  .header-control {
    border: 1.5px solid #eaedf0;
    border-radius: 40px;
    width: 100%;
    padding: 0 100px 0 47px;
    min-width: 0;
    height: 50px; }
    .header-control::placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #b3bac3; }
  .header-search {
    position: relative;
    max-width: 280px; }
    .header-search__icon {
      position: absolute;
      top: 15px;
      left: 20px;
      margin-right: 5px; }
  .header-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: var(--main-blue);
    border-radius: 60px;
    padding: 12px 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff; }
  .header-settings-btn {
    margin: 0 40px;
    border: none;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0077ff;
    background-color: var(--main-blue);
    border-radius: 100%; }
    .header-settings-btn img {
      width: 70%;
      height: 70%; }
  .header .profile-button {
    position: relative;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 8px;
    align-items: center;
    align-content: center; }
    .header .profile-button div.d-flex {
      align-items: center;
      grid-gap: 10px;
      gap: 10px; }
    .header .profile-button .profile-avatar {
      background: #ab47bc;
      border-radius: 50px;
      width: 39px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white; }
  .header .profile-dropdown {
    position: absolute;
    top: 100%;
    left: -100px;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    display: none;
    border-radius: 18px;
    color: #333333;
    background: rgba(247, 248, 249, 0.88);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    padding: 12px;
    width: 400px; }
    .header .profile-dropdown .profile-card {
      background: white;
      border-radius: 12px;
      padding: 13px 14px; }
      .header .profile-dropdown .profile-card .profile-link {
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 20px;
        padding: 4px 10px;
        font-size: 12px;
        font-weight: normal;
        color: black; }
    .header .profile-dropdown .change-theme-button {
      background: white;
      box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      padding: 4px 10px;
      font-size: 12px;
      font-weight: normal;
      margin-left: 15px; }
  .header .profile-button:hover {
    background: rgba(255, 255, 255, 0.1); }

.input__wrapper {
  display: flex;
  align-items: center; }
  .input__wrapper .input__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #b3bac3;
    white-space: nowrap;
    margin-right: 15px; }
  .input__wrapper .select2-container .select2-selection__rendered {
    padding: 0;
    font-family: "SFProDisplay";
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #b3bac3; }
  .input__wrapper .select2-container.select2-container--default .select2-selection {
    border: 1.5px solid #eaedf0;
    border-radius: 40px;
    min-width: 140px;
    display: flex;
    align-items: center;
    max-height: 50px; }
    .input__wrapper .select2-container.select2-container--default .select2-selection.select2-selection--multiple {
      padding: 0; }
      .input__wrapper .select2-container.select2-container--default .select2-selection.select2-selection--multiple .select2-selection__rendered {
        overflow: auto;
        width: 100%;
        height: 100%;
        padding: 13px 20px;
        display: flex;
        flex-wrap: wrap; }
    .input__wrapper .select2-container.select2-container--default .select2-selection__choice {
      background: #f8fbff;
      display: flex;
      align-items: center;
      padding: 6px 12px;
      color: var(--main-blue);
      border: 1px solid #eef5ff;
      border-radius: 29px; }
      .input__wrapper .select2-container.select2-container--default .select2-selection__choice__remove {
        order: 2;
        width: 16px;
        height: 16px;
        margin: 0 0 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: transparent;
        position: relative; }
        .input__wrapper .select2-container.select2-container--default .select2-selection__choice__remove:after {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: "";
          background: url(/static/media/fi_chevron-down.9c1333a3.svg) center no-repeat; }
    .input__wrapper .select2-container.select2-container--default .select2-selection__arrow {
      width: 24px;
      top: 0;
      bottom: 0;
      right: 20px;
      height: 100%;
      display: flex;
      align-items: center; }
      .input__wrapper .select2-container.select2-container--default .select2-selection__arrow b {
        font-weight: unset;
        position: static;
        margin: 0 0 0px;
        left: unset;
        top: unset;
        border: none;
        background: url(/static/media/fi_chevron-down.9c1333a3.svg) no-repeat center;
        width: 24px;
        height: 24px;
        transition: 0.15s ease-in-out; }
  .input__wrapper .select2-container.select2-container--open .select2-selection__arrow b {
    transform: rotateX(180deg); }
  .input__wrapper .select2-container .select2-selection {
    background: #ffffff;
    border: 1px solid #d4d6e3;
    border-radius: 6px;
    padding: 15px 20px;
    height: 55px;
    width: 100%;
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px; }
  .input__wrapper .select2-container .select2-dropdown {
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 51, 108, 0.05);
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 6px; }
    .input__wrapper .select2-container .select2-dropdown--below {
      margin-top: 4px; }
    .input__wrapper .select2-container .select2-dropdown--above {
      margin-bottom: 8px; }
    .input__wrapper .select2-container .select2-dropdown .select2-search--dropdown {
      padding: 0;
      margin-bottom: 8px; }
    .input__wrapper .select2-container .select2-dropdown .select2-search__field {
      border-radius: 6px;
      border: 1px solid #d4d6e3;
      background: #fff;
      padding: 14px 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #00275d; }
    .input__wrapper .select2-container .select2-dropdown .select2-results__option {
      padding: 14px 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #808d9c;
      border: 1px solid transparent;
      border-radius: 4px;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: 0.15s ease-in-out;
      background: #fff; }
      .input__wrapper .select2-container .select2-dropdown .select2-results__option[aria-selected="true"] {
        border-color: #eef5ff;
        background: #f8fbff;
        color: #004098; }
      .input__wrapper .select2-container .select2-dropdown .select2-results__option:hover, .input__wrapper .select2-container .select2-dropdown .select2-results__option--highlighted {
        border-color: #eef5ff;
        background: #f8fbff;
        color: #004098; }

@media (max-width: 767px) {
  .header-nav {
    width: 100%; } }

.sidebar {
  min-width: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  padding: 40px 15px; }
  @media (max-width: 1500px) {
    .sidebar {
      padding: 40px 15px; } }
  @media (max-width: 992px) {
    .sidebar {
      width: 100px; } }
  .sidebar-list {
    overflow: auto;
    height: calc(100vh - 250px); }
  .sidebar-list li {
    display: block; }
  .sidebar-list li:not(:first-child) {
    margin-top: 20px; }
  .sidebar-link {
    margin: auto;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f1f3f4;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #052549;
    transition: all 0.3s ease-in-out; }
    @media (max-width: 1500px) {
      .sidebar-link {
        width: 50px;
        height: 50px; } }
    .sidebar-link.not-working {
      color: var(--main-red);
      background: rgba(255, 69, 69, 0.1); }
    .sidebar-link.working {
      color: var(--main-green);
      background: rgba(7, 195, 82, 0.1); }
    .sidebar-link.active, .sidebar-link:hover {
      color: var(--main-blue);
      background: #e0efff; }
  .sidebar-button {
    display: flex;
    align-items: center;
    justify-content: center; }
  .sidebar-btn {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--main-blue);
    margin: auto; }
    .sidebar-btn img {
      width: 24px;
      object-fit: cover; }
    .sidebar-btn:not(:last-child) {
      margin-bottom: 30px; }
    @media (max-width: 1500px) {
      .sidebar-btn {
        width: 50px;
        height: 50px; } }
  .sidebar__inner {
    height: 100%;
    position: relative; }
  .sidebar__control {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }

.sidebar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  margin-right: auto; }
  .sidebar-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.modal-overflow .modal-content {
  overflow: initial; }

.update-modal .modal-content {
  max-width: 850px;
  max-height: 450px;
  overflow: initial; }

.update-btn {
  max-width: 114px;
  padding: 14px 23px;
  background: var(--main-blue);
  border-radius: 60px;
  color: white; }

.modal-footer.update {
  margin: 0 0 0 auto;
  width: -webkit-max-content;
  width: max-content;
  padding: 40px 0 40px 40px; }

.main {
  width: 100%;
  padding: 20px 20px 50px 20px; }
  @media (max-width: 1500px) {
    .main {
      padding: 20px; } }
  .main-card {
    box-shadow: 0px 4px 6px rgba(0, 51, 108, 0.05);
    border-radius: 8px; }
  .main-bottom {
    background: white; }
  .main-calendar {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .main-date {
    width: 230px;
    position: relative; }
  .main-img {
    position: absolute;
    top: 12px;
    left: 20px; }
  .main .date {
    background: #ffffff;
    border: 1.5px solid #eaedf0;
    border-radius: 40px;
    width: 100%;
    height: 50px;
    padding-left: 50px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #b3bac3; }
    .main .date::placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #b3bac3; }
  .main-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    text-transform: capitalize;
    color: white;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .main-top {
    position: relative;
    z-index: 2; }
    .main-top .caller-label {
      margin: 0; }
    .main-top .caller-wrapper {
      margin: 0; }
  .main-bottom {
    margin-top: 15px;
    overflow: auto; }
    .main-bottom.bottom {
      padding: 30px 0; }
  .main-content {
    border-bottom: 2px solid #f1f3f4;
    margin-bottom: 60px; }
    .main-content:last-child {
      margin-bottom: 0;
      border-bottom: none; }
  .main-footer {
    position: relative;
    padding: 30px 30px; }
    .main-footer::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #f1f3f4; }
  .main-search {
    max-width: 162px; }
    .main-search__icon {
      top: 8px; }
  .main-control {
    height: 38px; }

.customer .main-form {
  min-height: 550px; }

.checkbox {
  display: block;
  width: 20px;
  height: 20px; }
  .checkbox-box {
    padding: 0px 0px 0px 30px;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    line-height: 19px;
    color: #808d9c;
    transition: 0.3s linear; }
  .checkbox-input {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%; }
  .checkbox_border {
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid rgba(0, 64, 152, 0.5);
    transition: 0.15s ease-in-out; }
  .checkbox_bg {
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    background-image: url(/static/media/checkbox.8ca12125.svg);
    background-repeat: no-repeat;
    opacity: 0;
    border-radius: 4px;
    transform: scale(0);
    transition: 0.15s ease-in-out; }
  .checkbox-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #b3bac3;
    margin-bottom: 10px; }
  .checkbox_con {
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #052549; }
  .checkbox input[type="checkbox"]:checked ~ .checkbox_con, .checkbox input[type="checkbox"]:hover ~ .checkbox_con {
    color: #052549; }
  .checkbox input[type="checkbox"]:checked ~ .checkbox_border, .checkbox input[type="checkbox"]:hover ~ .checkbox_border {
    border-color: var(--main-blue); }
  .checkbox input[type="checkbox"]:checked ~ .checkbox_bg {
    opacity: 1;
    background-image: url(/static/media/checkbox.8ca12125.svg);
    border-radius: 4px;
    transform: scale(1); }

.sending-input {
  background: #ffffff;
  border: 1px solid #f1f3f4;
  border-radius: 4px;
  padding: 17px;
  width: 100%; }

.history-items {
  background: #ffffff;
  border: 1.5px solid #f1f3f4;
  box-shadow: 0px 3px 6px rgba(0, 51, 108, 0.05);
  border-radius: 4px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 8px;
  transition: all 0.3s ease-in-out; }
  .history-items:hover {
    border: 1.5px solid var(--main-blue);
    box-shadow: 0px 3px 6px rgba(0, 51, 108, 0.05); }
    .history-items:hover .history-tel {
      color: var(--main-blue); }
      .history-items:hover .history-tel span {
        color: var(--main-blue); }

.history-call {
  display: flex;
  align-items: center; }

.history-number {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background: #ffffff;
  border: 1px solid #f1f3f4;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #b3bac3; }

.history-tel {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #052549;
  transition: all 0.3s ease-in-out; }
  .history-tel span {
    color: #b3bac3;
    transition: all 0.3s ease-in-out; }

.history-day-time {
  width: 100%;
  flex: 1 1 auto;
  padding-right: 10px;
  margin-top: 3px;
  margin-left: auto;
  font-size: 14px;
  text-align: right; }

.history-icon {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  height: 24px; }
  .history-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.history-time {
  background: #e0efff;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  color: var(--main-blue); }

.history-company {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #b3bac3;
  margin-top: 10px; }
  .history-company span {
    color: #052549;
    margin-left: 5px; }

table {
  overflow-x: auto;
  scroll-behavior: smooth; }

.content-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1px;
  font-size: 0.9em;
  overflow-y: auto; }
  .content-table .columns_checkbox {
    position: relative; }
  .content-table .columns_modal {
    min-width: 300px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 0 10px #eee;
    border-radius: 4px;
    z-index: 1; }
    .content-table .columns_modal label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px;
      -webkit-user-select: none;
              user-select: none;
      transition: 0.1s; }
      .content-table .columns_modal label:hover {
        background-color: #eee; }
      .content-table .columns_modal label input {
        width: -webkit-min-content;
        width: min-content;
        margin-right: 10px; }

.patient-tr {
  cursor: pointer; }

.content-table thead tr {
  background-color: #f1f3f4;
  font-size: 14px;
  line-height: 17px;
  color: black;
  text-align: left;
  font-weight: bold; }

.content-table tbody tr {
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 6px 24px 0, rgba(0, 0, 0, 0.03) 0 0 0 1px;
  transition: all 0.3s ease-in-out;
  overflow: hidden; }
  .content-table tbody tr.active {
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 2px, #0077ff 0 0 0 1px; }

.content-table th,
.content-table td {
  padding: 10px 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* text */ }

.content-table tr td:nth-child(5) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 216px; }

.content-table td:last-child {
  white-space: pre; }

.table-grey {
  border: 1px solid #eaedf0; }

.d-flex.column-gap-2 {
  grid-gap: 12px;
  gap: 12px; }

.radio {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  position: relative;
  cursor: pointer; }
  .radio-input {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-color: var(--main-blue); }
    .radio-input:hover ~ .radio-radio {
      border-color: var(--main-blue); }
    .radio-input:checked ~ .radio-radio {
      border-color: var(--main-blue); }
      .radio-input:checked ~ .radio-radio::after {
        transform: translate(-50%, -50%) scale(1); }
  .radio-radio {
    width: 22px;
    height: 22px;
    border: 1.5px solid var(--main-blue);
    border-radius: 50%;
    margin-right: 10px;
    padding: 2px;
    position: relative;
    transition: all 0.2s ease-in-out; }
    .radio-radio::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      width: 12px;
      height: 12px;
      display: block;
      border-radius: 50%;
      background: var(--main-blue);
      transition: all 0.2s ease-in-out; }

.flex-100 {
  flex: 1 1 auto; }

.h-fit-content {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

.margin-top {
  margin-top: var(--margin-spacer, 1rem); }

.margin-left {
  margin-left: auto; }

.export-btn {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  padding: 10px 18px;
  font-weight: 500;
  color: #0077ff;
  border: 1px solid #0077ff; }
  .export-btn img {
    margin-right: 10px; }

.export-btn svg {
  margin-right: 10px; }

.export-btn svg path {
  fill: #0077ff; }

.flex-main {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.flex-end {
  display: flex;
  justify-content: flex-end; }

.mb-10 {
  margin-bottom: 10px; }

.mb-30 {
  margin-bottom: 30px; }

.min-w-100 {
  min-width: 100px; }

.min-w-140 {
  min-width: 140px; }

.min-w-500 {
  min-width: 500px;
  width: 100%; }

.min-w-600 {
  width: 100%;
  min-width: 600px; }

.min-w-700 {
  min-width: 700px; }

.min-w-800 {
  min-width: 800px;
  width: 100%; }

.operator-phone .modal-body {
  padding-left: 40px;
  padding-right: 40px; }

.operator-phone .modal-content {
  border-radius: 10px; }

.table-title {
  font-weight: 500;
  font-size: 18px;
  color: #052549;
  margin: 0 0 20px 0; }

.audio-download-button {
  display: block;
  width: 24px;
  height: 24px;
  padding: 0; }
  .audio-download-button svg {
    width: 100%;
    height: 100%; }

.audio-button {
  display: block;
  width: 24px;
  height: 24px;
  padding: 0; }

.mr_10 {
  margin-right: 10px; }

.mr_20 {
  margin-right: 20px; }

.ml_20 {
  margin-left: 20px; }

.w_full {
  width: 100%; }

.call-btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-blue);
  border-radius: 100%; }

.drop-down {
  position: relative; }
  .drop-down__call_window {
    opacity: 0; }
  .drop-down__inner {
    opacity: 0;
    position: fixed;
    max-width: 300px;
    z-index: 5000;
    pointer-events: none;
    background-color: #fff;
    transform: translate(-50%, 20px); }
  .drop-down_opened .drop-down__inner {
    opacity: 1;
    left: 50%;
    background-color: #fff;
    pointer-events: initial; }

.call_open .drop-down__call_window {
  position: fixed;
  top: 65px;
  left: 70%;
  opacity: 1;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  pointer-events: initial;
  transition: 0.2s ease; }

.call_open .modal.open + .drop-down .drop-down__call_window {
  left: 60% !important;
  right: -8%;
  top: 20%;
  transform: translate(120%, -50%); }

.call__income {
  display: flex;
  background-color: #fff;
  min-width: 300px;
  padding: 20px 0 30px 0;
  flex-direction: column;
  align-items: center;
  box-shadow: 8px 10px 100px #eae8f0;
  border-radius: 6px;
  grid-gap: 10px;
  gap: 10px; }

.call__income-subtitle {
  font-size: 18px;
  margin-bottom: 10px;
  color: #b3bac3; }

.call__income-caller {
  font-weight: 500;
  font-size: 24px;
  margin: 0 0 10px 0; }

.call__income-phone {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 20px; }

.call-status {
  display: block;
  width: -webkit-max-content;
  width: max-content;
  margin: 0 auto 25px auto;
  color: white;
  font-size: 14px;
  font-weight: 700; }

.call-keyboard {
  min-width: 250px;
  border-radius: 6px;
  background-color: #fff;
  padding: 24px 34px; }
  .call-keyboard__label {
    font-size: 20px;
    margin-bottom: 10px;
    padding-bottom: 5px; }
  .call-keyboard__phone {
    font-weight: 500;
    font-size: 20px;
    font-family: "SFProDisplay";
    color: #000;
    height: 28px;
    padding-left: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #f5f5f6; }
    .call-keyboard__phone::-webkit-outer-spin-button, .call-keyboard__phone::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
  .call-keyboard__number {
    width: 55px;
    height: 55px;
    font-weight: 500;
    font-size: 24px;
    color: #494d51;
    background-color: #f5f5f7;
    border-radius: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .call-keyboard__call, .call-keyboard__forward {
    width: 55px;
    height: 55px;
    display: block;
    align-items: center;
    justify-content: center;
    background-color: #07c352;
    border-radius: 100%;
    margin: 0 auto auto;
    padding: 0; }
  .call-keyboard__mute, .call-keyboard__hold {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fbb80f;
    border-radius: 100%;
    margin: 0 auto auto;
    padding: 0;
    box-shadow: 0 8px 14px 0 #fbb80f40; }
    .call-keyboard__mute img, .call-keyboard__hold img {
      width: 20px;
      height: 20px; }
  .call-keyboard__forward {
    background-color: #fff; }
  .call-keyboard__call img,
  .call-keyboard__forward img {
    width: 18px;
    height: 18px; }
  .call-keyboard__hangup {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff4545;
    border-radius: 100%;
    margin: 0 auto auto;
    padding: 0; }
  .call-keyboard__hangup img {
    width: 20px;
    height: 20px; }
  .call-keyboard__backspace {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0;
    margin: auto;
    margin-top: 0; }
  .call-keyboard__backspace img {
    width: 20px;
    height: 20px; }

.call-forward__modal {
  position: absolute;
  width: 300px;
  height: 470px;
  left: calc(-100% - 320px);
  top: 0;
  background: #fff;
  padding: 10px;
  box-shadow: inherit;
  border-radius: inherit; }
  .call-forward__modal-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 10px; }
  .call-forward__modal-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px; }
  .call-forward__modal-input {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .call-forward__modal-input .\--left {
      flex: 1 1;
      border: 1px solid #08305ec5;
      border-radius: 4px;
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      width: 100%; }
    .call-forward__modal-input input {
      background: #ffffff;
      width: 100%;
      height: 50px;
      padding: 15px;
      color: #08305ec5;
      outline: none; }
    .call-forward__modal-input button {
      background: var(--main-blue);
      border-radius: 6px;
      margin: 0 0 0 10px;
      height: 50px; }
  .call-forward__modal-prev {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px 15px 15px;
    height: 50px;
    border-right: 0;
    background: #eeeeee; }
  .call-forward__modal-lists {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
    max-height: 265px;
    overflow: auto;
    overflow-x: hidden; }
  .call-forward__modal-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid lightgray;
    padding-top: 10px; }
    .call-forward__modal-list-left h3 {
      margin-block: 0 10px; }
    .call-forward__modal-list .call-keyboard__forward {
      margin: 0; }

.application {
  display: flex;
  align-items: flex-start;
  overflow: auto;
  padding-left: 10px; }
  .application::-webkit-scrollbar {
    height: 0 !important; }
  .application__card {
    position: relative;
    padding: 15px 10px;
    background: #ffffff;
    border: 1.5px solid transparent;
    box-shadow: 0 3px 6px rgba(0, 51, 108, 0.05);
    border-radius: 6px;
    font-weight: 500;
    color: #b3bac3;
    cursor: pointer;
    display: block; }
  .application__card:hover {
    border: 1.5px solid #0077ff; }
  .application__card-add {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 50%;
    z-index: 50;
    transform: translate(-50%, -50%) scale(0);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in-out;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); }
  .application__card-add svg {
    width: 20px;
    height: 20px; }
  .application__separator {
    position: relative;
    height: 10px;
    background-color: transparent; }
  .application__separator-inner {
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: 0;
    right: 0;
    z-index: 50; }
  .application__separator-inner:hover + .application__card-add {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1); }
  .application__card-add:hover {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1); }
  .application__card-add svg path {
    stroke: #fff; }
  .application__client {
    margin: 0;
    font-weight: 500;
    font-size: 14px; }
  .application__number {
    font-weight: 500;
    color: var(--main-blue);
    margin: 10px 0; }
  .application__description {
    font-size: 18px; }
  .application__tags {
    display: flex;
    align-items: center;
    margin-top: 10px; }
  .application__tags > :not(:last-child) {
    padding-right: 15px;
    margin-right: 7px;
    position: relative; }
  .application__tags > :not(:last-child)::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #b3bac3; }
  .application__add-board {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
    min-width: 300px;
    width: 300px;
    height: 76px;
    font-weight: 700;
    font-size: 18px;
    color: #b3bac3;
    border: 1px dashed #b3bac3;
    background-size: 100%;
    border-radius: 6px;
    z-index: 3; }
  .application__add-board-between {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(75px / 2);
    right: 0;
    width: 0;
    height: 0;
    transform: translateY(-50%) translateX(50%);
    transition: 0.2s;
    border-radius: 50%;
    background-color: #fff;
    z-index: 123;
    overflow: hidden; }
    .application__add-board-between svg {
      width: 20px;
      height: 20px; }
      .application__add-board-between svg path {
        stroke: black !important; }
  .application .board:hover .application__add-board-between {
    display: flex;
    animation-name: animate;
    animation-duration: 0.3s;
    animation-fill-mode: forwards; }

@keyframes animate {
  from {
    width: 0;
    height: 0; }
  to {
    width: 30px;
    height: 30px; } }

.blackboard {
  min-width: 300px;
  width: 300px;
  height: calc(100vh - 120px);
  position: relative; }
  .blackboard__actions {
    min-width: 350px;
    height: 600px;
    overflow-y: auto;
    border-radius: 10px;
    background-color: #fff;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; }
  .blackboard__action {
    color: #052549;
    font-size: 14px;
    font-weight: 500; }
  .blackboard__heading {
    padding: 20px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--main-blue); }
  .blackboard__name {
    font-weight: 700;
    font-size: 24px;
    padding: 4px;
    border-radius: 6px;
    color: #ffffff;
    background-color: transparent;
    transition: 0.1s ease-in-out;
    margin-right: 20px; }
  .blackboard__name:focus {
    background-color: #fff;
    color: #000; }
  .blackboard__count {
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
    font-weight: 700;
    font-size: 12px; }
  .blackboard__count svg {
    width: 100%; }
  .blackboard__body {
    padding: 0 5px;
    height: 100%;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    max-height: calc(100% - 85px);
    overflow: auto; }
  .blackboard__add-task {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%; }
  .blackboard__add-task svg path {
    stroke: #fff; }

.modal-test .mx-modal-wrapper {
  max-width: 800px !important;
  max-height: 800px !important; }

.btn_copy {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  padding: 0; }
  .btn_copy svg {
    width: 100%;
    height: 100%; }

.main-call-btn {
  position: fixed;
  bottom: 30px;
  right: 30px; }

.color-radio {
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  position: relative; }
  .color-radio > input:checked + .color-radio-active {
    opacity: 1; }
  .color-radio-active {
    display: block;
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 100%;
    border: 2px solid #4aa9f8;
    opacity: 0; }

.board__title {
  color: #052549;
  margin: 0; }

.mt_20 {
  margin-top: 20px; }

.mt_30 {
  margin-top: 30px; }

.mr_5 {
  margin-right: 5px; }

.w_24 {
  width: 24px; }

.h_24 {
  height: 24px; }

.p_0 {
  padding: 0; }

.p_25 {
  padding: 25px; }

.size_small .modal-select__control {
  min-height: 40px !important;
  height: 40px !important; }

.select_xsm .modal-select__control {
  min-height: 35px !important;
  height: 35px;
  border-color: #f1f3f4 !important; }

.task__modal-comment {
  display: block;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #052549;
  opacity: 0.7; }

.task__description-box {
  border: 1px solid #f1f3f4;
  border-radius: 4px;
  padding: 10px; }

.task-btn {
  width: 100px;
  padding: 10px 0;
  border-radius: 6px;
  text-align: center; }

.task__create-btn {
  background-color: #0077ff;
  color: #fff;
  margin-right: 10px; }

.task__cancel-btn {
  color: #0077ff; }

.secondary-control {
  border: 2px solid #f1f3f4;
  border-radius: 4px; }

.secondary-select .modal-select__control {
  min-height: 23px !important;
  height: 23px !important;
  font-size: 14px;
  border: 2px solid #f1f3f4 !important; }

.secondary-select
.modal-select__indicator.modal-select__dropdown-indicator
svg {
  width: 16px;
  height: 16px; }

.flex-max {
  flex: 1 1; }

.margin-right {
  display: block;
  max-width: -webkit-max-content;
  max-width: max-content;
  margin: auto 0 auto auto; }

.circle-primary-blue circle {
  fill: var(--main-blue); }

.no-border .modal-select__control {
  border: none !important;
  outline: none !important; }

.no-border .modal-select__control--is-focused {
  border: none !important;
  outline: none !important; }

.no-border .modal-select__control--menu-is-opened {
  border: none !important;
  outline: none !important; }

.no-notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #8a9499; }
  .no-notification svg {
    color: #8a9499;
    width: 60px;
    margin-bottom: 20px; }

.white-space_no-wrap {
  white-space: nowrap; }

.reason_table {
  width: 95%;
  margin: 50px auto; }
  .reason_table-header {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .reason_table tbody {
    cursor: pointer; }

.reason-modal .mx-modal-wrapper {
  height: -webkit-max-content;
  height: max-content; }

.reason-modal .modal-content {
  border: none;
  border-radius: 20px;
  overflow: hidden; }

.reason-modal .modal-body {
  padding: 30px; }

.reason-modal form {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  .reason-modal form .form-group {
    width: 100%; }
  .reason-modal form input {
    margin-top: 10px;
    padding: 20px !important;
    border: 1px solid #f1f3f4;
    border-radius: 20px;
    margin-bottom: 10px; }
  .reason-modal form button.btn {
    margin-left: auto; }

.tippy-tooltip {
  padding: 0;
  background: transparent; }

.cs-paginate-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px; }

.cs-paginate-left {
  display: flex;
  align-items: center;
  color: #b3bac3; }
  .cs-paginate-left span {
    color: #000;
    margin-left: 10px; }

.cs-paginate-right {
  color: #b3bac3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; }

.cs-paginate {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center; }
  .cs-paginate li {
    cursor: pointer;
    background: #EBF0EF; }
    .cs-paginate li a {
      color: #b3bac3;
      width: 100%;
      display: flex;
      min-width: 48px;
      padding: 10px 12px;
      text-align: center;
      border-radius: 40px;
      height: 40px;
      color: #58747A;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px; }
    .cs-paginate li.selected a {
      border: 1px solid #D7E0DF;
      background: #fff;
      color: #20383D; }
    .cs-paginate li:nth-child(2) {
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px; }
    .cs-paginate li:nth-last-child(2) {
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px; }
  .cs-paginate .previous {
    margin-right: 8px; }
  .cs-paginate .next {
    margin-left: 8px; }
  .cs-paginate .previous, .cs-paginate .next {
    border: 1px solid #D7E0DF;
    border-radius: 40px;
    background: #fff; }
  .cs-paginate .previous.disabled, .cs-paginate .next.disabled {
    border: 1px solid transparent;
    background: #EBF0EF; }
  .cs-paginate li:first-child a,
  .cs-paginate li:last-child a {
    color: currentColor; }

.new_date_picker_container {
  display: flex;
  width: -webkit-max-content;
  width: max-content;
  height: 50px;
  padding: 14px 20px;
  border-radius: 40px;
  border: 1.5px solid #eaedf0; }

.pbx {
  display: flex;
  align-items: flex-start; }
  .pbx_menus_list {
    width: 20%;
    height: calc(100vh - 80px);
    overflow: auto;
    background-color: #fff;
    padding: 20px; }
    .pbx_menus_list--item {
      padding: 20px;
      border-radius: 20px;
      background-color: var(--main-blue);
      color: #fff;
      cursor: pointer;
      font-weight: normal;
      box-shadow: 0px 8px 14px rgba(0, 119, 255, 0.25); }
  .pbx_content {
    padding: 20px;
    width: 80%;
    height: calc(100vh - 80px);
    overflow: auto; }
  .pbx .blacklist_modal .mx-modal-wrapper {
    height: -webkit-max-content;
    height: max-content; }
  .pbx .blacklist_modal .modal-content {
    border: none;
    border-radius: 20px;
    overflow: hidden; }
  .pbx .blacklist_modal .modal-body {
    padding: 30px; }
  .pbx .blacklist_modal form {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    .pbx .blacklist_modal form .form-group {
      width: 100%;
      margin-bottom: 20px; }
    .pbx .blacklist_modal form .has-error input {
      border-color: red; }
    .pbx .blacklist_modal form input {
      margin-top: 10px;
      padding: 20px !important;
      border: 1px solid #f1f3f4;
      border-radius: 20px; }
      .pbx .blacklist_modal form input.error {
        border-color: red; }
    .pbx .blacklist_modal form button.btn {
      margin-left: auto;
      box-shadow: 0px 8px 14px rgba(0, 119, 255, 0.25); }
  .pbx .blacklist_table-header {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .pbx .blacklist_table-header button {
      box-shadow: 0px 8px 14px rgba(0, 119, 255, 0.25); }

.btn_red {
  background: var(--main-red);
  box-shadow: 0px 8px 14px rgba(255, 69, 69, 0.25); }

.App-main {
  width: 100%;
  padding-top: 20px;
  position: relative; }

.modal-select__placeholder {
  color: rgba(8, 48, 94, 0.5) !important; }

.react-daterange-picker__wrapper {
  border: none; }

.main-date {
  width: unset; }

.service-textarea {
  padding: 8px;
  background: #fff !important;
  border: 1px solid #08305ec5 !important;
  border-radius: 4px !important;
  min-height: 50px !important;
  min-width: 140px;
  color: #08305ec5 !important; }

.textarea_cs {
  background: #fff !important;
  border: 1px solid #08305ec5 !important;
  border-radius: 4px !important;
  color: #08305ec5;
  font-family: inherit; }

.main-bottom {
  overflow: unset; }

.content-table thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0; }

.content-table-parent {
  width: 100%;
  overflow: auto;
  max-height: 1482px; }

.main.call-center .content-table-parent {
  overflow: unset; }

.filter {
  position: fixed;
  right: 5px;
  top: 65px;
  bottom: 5px;
  background: white;
  box-shadow: 0 3px 6px rgba(0, 51, 108, 0.2);
  padding: 32px;
  z-index: 1;
  border-radius: 8px; }
  .filter-columns {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px; }
  .filter .close-btn {
    position: absolute;
    right: 12px;
    top: 12px;
    background: #ebf0ef;
    padding: 4px;
    border-radius: 100px;
    width: 32px;
    height: 32px; }
    .filter .close-btn img {
      width: 8px;
      height: 8px; }

.theme-background-image {
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position-x: 0px;
  background-position-y: 0px;
  background-attachment: fixed;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

.export-type-btn {
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-content: center;
  align-items: center; }

.export-type-btn:hover {
  background: rgba(255, 255, 255, 0.4); }

