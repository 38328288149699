.pbx {
	display: flex;
	align-items: flex-start;
	&_menus_list {
		width: 20%;
		height: calc(100vh - 80px);
		overflow: auto;
		background-color: #fff;
		padding: 20px;
		&--item {
			padding: 20px;
			border-radius: 20px;
			background-color: var(--main-blue);
			color: #fff;
			cursor: pointer;
			font-weight: normal;
			box-shadow: 0px 8px 14px rgba(0, 119, 255, 0.25);
		}
	}
	&_content {
		padding: 20px;
		width: 80%;
		height: calc(100vh - 80px);
		overflow: auto;
	}
	.blacklist {
		&_modal {
			.mx-modal-wrapper {
				height: max-content;
			}
			.modal-content {
				border: none;
				border-radius: 20px;
				overflow: hidden;
			}
			.modal-body {
				padding: 30px;
			}
			form {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				.form-group {
					width: 100%;
					margin-bottom: 20px;
				}
				.has-error {
					input {
						border-color: red;
					}
				}
				input {
					margin-top: 10px;
					padding: 20px !important;
					border: 1px solid rgb(241, 243, 244);
					border-radius: 20px;
					&.error {
						border-color: red;
					}
				}
				button.btn {
					margin-left: auto;
					box-shadow: 0px 8px 14px rgba(0, 119, 255, 0.25);
				}
			}
		}
		&_table {
			&-header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				button {
					box-shadow: 0px 8px 14px rgba(0, 119, 255, 0.25);
				}
			}
		}
	}
}
