@import "../../assets/styles/varables";

.modal {
	position: fixed;
	//overflow: hidden;
	top: 0;
	right: 0;
	z-index: 2000;
	width: 100%;
	height: 100%;
	outline: 0;
	display: flex;
	justify-content: center;

	color: $inputSelectedText !important;

	/* align-items: center; */
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: 100vh;
	z-index: 1500;
	background: rgba(25, 25, 35, 0.5);
	opacity: 0.6;
}

.mx-modal-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	top: 50%;
	max-height: calc(100% - 50px);
	position: relative;
	z-index: 4000;
	max-width: 1100px;
	height: fit-content;

}

.modal-content::-webkit-scrollbar {
	display: none;
}

.modal-content {
	position: relative;
}

.modal-row {
	row-gap: 20px;
}

/* Modal Select */

.field-container {
	width: 100%;
}

.modal-select {
	&-container {
		width: 100%;
	}

	width: 100%;

	&__menu {
		z-index: 20;

		::-webkit-scrollbar {
			// display: none;
			width: 5px;
		}

		::-webkit-scrollbar-thumb {
			background: #00000030;
			border-radius: 10px;
		}
	}

	&__control {
		background: $inputBackground !important;
		border: 1px solid $inputBorderColor !important;
		border-radius: $inputRadius !important;
		min-height: 38px !important;
		min-width: 140px;
		display: flex;
		align-content: center;
	}

	&__indicators {
		background: #F4F4F6;
		border-left: 1px solid #E1E3EA;
		border-radius: 0 $inputRadius $inputRadius 0;
	}

	&__input-container,
	&__single-value,
	&__placeholder {
		padding: 0;
		font-family: SFProDisplay;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: $inputSelectedText !important;
	}

	&__indicator-separator {
		display: none;
	}
}

.textarea .form-group {
	width: 100%;
}

#modal-loader {
	width: 80vh;
	max-width: 1190px;
	height: 80vh;
	position: relative;

	.loader {
		font-size: 80px;
	}
}

.pluginsModall {
	padding: 20px;
	position: relative;
	width: 800px;
	height: 560px;
	background: #fff;
}

.settingsModall {
	//padding: 50px 80px 32px 80px;
	padding: 30px;
	position: relative;
	width: 700px;
	background: #fff;
	
	&__text {
		font-weight: 700;
		font-size: 24px;
		line-height: 30px;
		color: #052549;
		//margin-bottom: 30px;

		margin-bottom: 40px;
		text-align: center;
	}

	&__list {
		width: 100%;
		max-height: 560px;
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 24px;
	}

	&__item {
		display: flex;
		align-items: center;
		width: 305px;
		margin: 0 !important;
	}
	&__link {
		display: flex;
		align-items: center;
		padding: 12px 24px;
		background: #ffffff;
		border-radius: 4px;
		width: 100%;
		border: 1px solid #CACDD8;

		p {
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
			color: #052549;
		}

		img {
			width: 24px;
			height: 24px;
			margin-right: 16px;
		}
	}

	&__close {
		//position: absolute;
		//top: 20px;
		right: 12px;
		font-size: 18px;
		position: fixed;
		top: 12px;

		svg {
			width: 16px;
			height: 16px;
		}

	}

	&__subtext {
		font-weight: 600;
		font-size: 30px;
		line-height: 36px;
		color: #052549;
		margin-bottom: 40px;
	}
}

.theme-list {
	row-gap: 4px;

	.col-3 {
		padding: 0;
		margin: 0;
	}
}

.theme-item {
	border-radius: 5px;
	overflow: hidden;
	border: 4px solid transparent;
	height: 148px;
	margin: 0;

	img {
		object-fit: cover;
		width: 185px;
		height: 140px;
	}
}

.theme-item:hover {
	border: 4px solid #117ae1
}
