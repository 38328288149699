.app {
	&__task-log-heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px;
	}

	&__task-log-title {
		font-size: 24px;
		color: #052549;
		margin: 0;
	}
}

.app-view {
	flex-wrap: nowrap;
	font-family: "SF Pro Display";
	display: flex;
	height: calc(100vh - 120px);
	width: 100vw;

	&__navbar {
		display: flex;
		padding: 10px 40px 0;
		border-bottom: 1px solid #ccc;

		.navbar-item {
			padding: 10px 20px;
			color: rgba(0, 0, 0, 0.4);
			cursor: pointer;
		}

		.navbar-item.active {
			font-weight: bold;
			color: rgba(0, 0, 0, 1);
			border-bottom: 2px solid black;
		}

		.app-view__content {
			margin-top: 20px;
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		max-width: 480px !important;
		padding: 0 20px 0 0;

		.info-inner-wrapper {
			background: #ffffff;
			box-shadow: 0 4px 6px rgba(0, 51, 108, 0.05);
			border-radius: 12px;
			overflow: scroll;
			height: 100%;

		}
	}

	&__info-application {
		width: 100%;
		padding: 20px;
	}

	&__info-back {
		display: flex;
		align-items: center;
		gap: 10px;
		color: #000;
		font-size: 20px;
		font-weight: 700;
		line-height: 28px;
		text-align: left;
	}

	&__info-image {
		width: 100px;
		height: 100px;
	}

	&__info-name {
		font-size: 24px;
		font-weight: 700;
		line-height: 28px;
		text-align: left;
	}

	&__info-type {
		font-size: 18px;
		font-weight: 500;
		line-height: 24px;
		text-align: left;
		color: rgba(0, 0, 0, 0.35);
	}

	.info-call {
		width: 355px;
		padding: 10px 0;
	}

	.border-bottom {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.info-call__name {
		font-size: 16px;
		font-weight: 600;
		line-height: 22px;
		text-align: left;
		color: rgba(0, 0, 0, 0.5);
	}

	&__info-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		color: #fff;
		font-size: 14px;
		font-weight: 600;
		background: #0bcf74;
		padding: 11px 20px;
		border-radius: 8px;
	}

	&__info-img {
		width: 18px;
		height: 18px;
	}

	&__info-id {
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: rgba(255, 255, 255, 0.6);
		margin-bottom: 10px;
	}

	&__info-unsorted {
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		margin-bottom: 5px;
		color: #ffffff;
		span {
			color: rgba(255, 255, 255, 0.6);
		}
	}

	&__info-lines {
		width: 100%;
		height: 5px;
		align-items: center;
	}

	&__info-fline {
		width: 80px;
		height: 100%;
		background: #f1f3f4;
	}

	&__info-lline {
		flex: 1;
		height: 100%;
		background: #f1f3f44d;
	}

	&__info-content {
		height: 100%;
	}

	&__info-user {
		padding: 0 12px 20px 20px;
	}

	&__info-contact {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
		gap: 20px;
	}

	&__info-card {
		display: flex;
		gap: 15px;
		color: rgba(0, 0, 0, 0.35);

		.image {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			background: #ececec;
			border-radius: 50%;
		}

		img {
			width: 35%;
		}
	}

	&__info-item {
		margin-top: 10px;
		padding: 20px;
		background: #ffffff;
		border: 1px solid #f1f3f4;
		box-shadow: 0px 3px 6px rgba(0, 51, 108, 0.05);
		border-radius: 6px;
	}

	&__info-subtext {
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		margin: 0 16px 0px 0;
		text-align: left;
	}

	&__info-userName {
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: #0077ff;
	}

	&__info-item-text {
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #0077ff;
	}
	&__info-item-text:not(:last-child) {
		margin-bottom: 6px;
	}

	&__info-item-subtext {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #b3bac3;
		margin-right: 20px;
	}
	&__info-item-subtext:not(:last-child) {
		margin-bottom: 8px;
	}

	&__info-item-top {
		margin-bottom: 10px;
		p {
			margin: 0;
		}
	}

	// START VIEW CONTENT PART
	&__content {
		position: relative;
		background: #ffffff;
		box-shadow: 0 4px 6px rgba(0, 51, 108, 0.05);
		border-radius: 12px;
		padding: 0;
		display: flex;
		flex-direction: column;
		height: 100%;
		flex: 1 1 0;
		gap: 10px;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	&__content-main {
		height: 100%;
		overflow-y: scroll;
	}

	&__content-text {
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
		text-align: left;
		color: #2c3659;
	}

	&__content-item {
		display: flex;
		padding: 15px;
		background: #ffffff;
		border-bottom: 2px solid #f1f3f4;
		margin: 10px 0;
	}

	&__content-item-main {
		gap: 100px;
	}

	&__content-item-text {
		font-size: 18px;
		font-weight: 600;
		line-height: 20px;
		text-align: left;
		color: #2c3659;
	}

	&__content-item-date {
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		text-align: left;
		color: #a7aec1;
		padding-top: 4px;
	}

	&__call-status {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		background: #eff2f3;
		border-radius: 50%;
	}

	&__content-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		color: #2C3659;
		font-size: 14px;
		font-weight: 600;
		background: #E2E4EA;
		width: 140px;
		height: 40px;
		border-radius: 8px;
	}

	&__content-img {
		width: 20px;
		height: 20px;
	}

	.app-view__content-bl {
		color: #0077ff;
	}

	&__content-bold {
		color: #000;
	}

	&__content-area {
		padding: 0 20px;
		width: 100%;
		gap: 15px;
		font-size: 16px;
	}
}

// application-card

.application-card__wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	//height: 100%;
	height: auto;
	gap: 10px;
}

.application-card {
	width: calc(100% - 30px);
	margin: 0 20px;
	padding: 20px;
	border-radius: 12px;
	//background: rgba(255, 0, 0, 0.06);
	background: #FFA92714;

	&__id {
		font-size: 20px;
		font-weight: 600;
		line-height: 28px;
		text-align: left;
	}

	&__operator {
		padding: 5px 15px;
		background: rgba(255, 224, 224, 1);
		border-radius: 16px;
		font-size: 15px;
		font-weight: 500;
		line-height: 20px;
		text-align: left;
		color: red;
	}

	&__info {
		margin-top: 10px;
		gap: 50px;

		&-name {
			color: rgba(0, 0, 0, 0.4);
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			text-align: left;
		}

		&-data {
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			text-align: left;
		}
	}

	&__audio-player {
		background: rgba(0, 14, 143, 0.05);

		height: 60px;
		width: 100%;
		border-radius: 12px;
	}

	&__status {
		display: flex;
		width: 100%;
		margin-top: 30px;
		border-radius: 0;
		justify-content: center;

		.status-item {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 60px;
			width: 25%;
			color: #fff;
			background: rgba(14, 89, 230, 0.9);
			font-size: 15px;
			font-weight: 600;
			line-height: 20px;
			text-align: left;
			padding: 10px 20px;
			clip-path: polygon(95% 0%, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);
			border-radius: 0;
			margin-left: -10px;

			&:last-child {
				background: rgba(255, 169, 39, 1);

				&::after {
					border-left: 15px solid rgba(255, 169, 39, 1);
				}
			}

			&__name {
				font-size: 16px;
				font-weight: bold;
			}

			&__date {
				font-size: 14px;
				margin-top: 5px;
			}
		}
	}
}

.app-view__buttons {
	display: flex;
	align-items: center;
	gap: 20px;
	position: relative;
	width: 100%;
	margin: 15px 0;
}

.file-btn{
	display: flex;
	align-items: center;
	gap: 10px;
}

.add-notification {
	top: 50%;
	right: 0;
	background-color: var(--main-blue);
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;

	& svg path {
		fill: #fff;
	}
}

.submit-btn {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-left: auto;
}

.task-log {
	position: absolute;
	bottom: calc(50vh - 390px);
	left: 50px;
	z-index: 100;
	transform: translateY(-50%) scale(0);
	opacity: 0;
	pointer-events: none;
	padding: 20px;
	border-radius: 15px;
	background-color: #fff;
	transition: 0.3s ease-in-out;
	max-width: 568px;
	box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);

	&_opened {
		transform: translateY(-20%) scale(1);
		opacity: 1;
		pointer-events: initial;
	}
}

// Customer info component

.customer-info {
	padding: 50px;

	.info-title {
		font-size: 22px;
		font-weight: 700;
		line-height: 30.05px;
		text-align: left;
	}

	.info-card {
		margin-top: 30px;
		gap: 30px 0;
	}

	.info-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;

		&__name {
			font-size: 15px;
			font-weight: 500;
			line-height: 20.49px;
			color: rgba(0, 0, 0, 0.4);
		}

		&__data {
			font-size: 18px;
			font-weight: 600;
			line-height: 24px;
		}
	}
}

.application-card-view-flex {
	height: 100vh;
}

.application-card__wrapper {
	margin: 10px 0;
	.chat-message-wrapper {
		padding: 5px 20px;

		.message-owner {
			color: black;
			font-weight: 500;
			font-size: 14px;
		}

		.chat-message {
			padding: 20px 22px;
			background: #F8FFEE;
			border-radius: 0 10px 10px 10px;
			width: fit-content;
			min-width: 280px;
			font-size: 16px;
			line-height: 24px;
			margin-top: 12px;
			border: 1px solid #0000001A;

			.created-at {
				color: #000000;
				font-size: 14px;
			}

			.download-file {
				background: #ECF1FF;
				border-radius: 50px;
				padding: 9px;
				width: 38px;
				height: 38px;
			}
		}


	}
}