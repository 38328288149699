.react-select__control {
    box-shadow: none !important;
    border: 1.5px solid #8891AA !important;
}

.react-select__single-value {
    color: #808d9c !important;
}

.react-select__menu {
    color: #808d9c !important;
    border-radius: 10px !important;
    background: #fff !important;
    border: 1px solid transparent !important;
    margin: 4px 0 0 0 !important;
    z-index: 9 !important;
}

.react-select__menu::-webkit-scrollbar{
    width: 0;
    height: 0;
}

.react-select__indicators {
    background: #F4F4F6;
    border-radius: 0 4px 4px 0;
}


.react-select__option{
    padding: 12px 14px !important;
    border-radius: 4px;
    border: 1px solid  transparent;
}

.react-select__input{
    width: 90px !important;
    max-width: 90px;
}

.react-select__input-container{
    max-width: 90px;
}