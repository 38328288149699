.react-daterange-picker__calendar
{
    width: 353.6px;
    .react-calendar 
    {
        border-radius: 8px;
        border: 1px solid rgb(222, 225, 230);
        overflow: hidden;
        padding: 8px;
        width: 360px !important;
        &__navigation
        {
            margin-bottom: 5px;
            &__arrow,
            &__label 
            {
                position: relative;
                font-size: 20px;
                line-height: 1.6;
                font-weight: 600;
                color: #052549;
                &:enabled:hover,
                &:enabled:focus
                {
                    background-color: #F1F3F4;
                }
            } 
            &__label 
            {
                font-size: 20px;
                font-weight: 600;
                color: #052549;
                border-radius: 12px;
            }
            &__arrow 
            {
                border-radius: 50%;
                font-size: 20px;
                font-size: 0;
            }
            %centralize
            {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &__prev-button::before 
            {
                content: url('../../../assets/images/svg/calendar-prev.svg');
                @extend %centralize;
            }

            &__prev2-button::before 
            {
                content: url('../../../assets/images/svg/calendar-prev2.svg');
                @extend %centralize;
            }

            &__next-button::before 
            {
                content: url('../../../assets/images/svg/calendar-next.svg');
                @extend %centralize;
            }

            &__next2-button::before 
            {
                content: url('../../../assets/images/svg/calendar-next2.svg');
                @extend %centralize;
            }
        }
    
        &__month-view__weekdays__weekday
        {
            abbr {text-decoration: none;}

            width: 48px;
            aspect-ratio: 1/1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;

        }
    
        &__tile 
        {
            width: 32px;
            min-width: 32px;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            font-size: 18px;
            font-weight: 400;
            &:enabled:hover,
            &:enabled:focus
            {
                background-color: #F1F3F4;
            }
    
            &:enabled:active
            {
                background-color: #B3BAC3;
            }
        }
    
        &__tile--now 
        {
            background-color: var(--main-blue);
            color: #fff;
            &:enabled:hover,
            &:enabled:focus
            {
                background-color: var(--main-blue)
            }
        }
    
        &__tile--active
        {
            background-color: #E0EFFF;
            color: var(--main-blue);
            border-radius: unset;
            &:enabled:hover,
            &:enabled:focus
            {
                background-color: #E0EFFF;
            }
        }
    
        &__tile--rangeStart, &__tile--rangeEnd 
        {
            background-color: var(--main-blue);
            color: #fff;
            &:enabled:hover,
            &:enabled:focus
            {
                background-color: var(--main-blue);
            }
        }
    
        &__tile--rangeStart{
    
            border-radius: 8px 0 0 8px;
        }
    
        &__tile--rangeEnd 
        {
            border-radius: 0 8px 8px 0;
        }
    
        &__tile--rangeBothEnds
        {
            border-radius: 8px;
        } 
    }
}
