.barchart {
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    path {
        transition: all .1s;
        position: relative;
        z-index: 10;
        filter: none;
    }

    .apexcharts-tooltip {
        width: 0;
        height: 0;
        // transform: translate(-30px);
        overflow: visible;
    }
}

#barchart-tooltip {
    position: sticky;
    width: max-content;
    padding: 10px 35px;
    transform: translate(-50%, -135%);

    background: #FFFFFF;
    border: 1px solid #E0EFFF;
    box-shadow: 0px 14px 20px rgba(0, 35, 74, 0.05);

    h4 {
        text-align: center;
        color: #052549;
        margin-bottom: 4px;

        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
    }

    p {
        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */

        text-transform: uppercase;

        /* secondary */

        color: #B3BAC3;
    }

    span {
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 18px;
        height: 18px;
        z-index: -1;
        transform: translate(-50%, 50%) rotate(45deg);
        background: #FFFFFF;
        border-bottom: 1px solid #E0EFFF;
        border-right: 1px solid #E0EFFF;
        box-shadow: 0px 14px 20px rgba(0, 35, 74, 0.05);

    }
}