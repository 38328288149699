.billing-error {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;


    &__inner {
        //width: 750px;
        width: 800px;
        padding-block: 80px 40px;
        padding-inline: 60px;


        background: #FFFFFF;
        box-shadow: 0 0 50px -5px rgba(0, 0, 0, 0.25);
        border-radius: 20px;

        display: flex;
        flex-direction: column;
        align-items: center;


    }

    &__title {
        font-weight: 600;
        //font-size: 40px;
        font-size: 36px;
        color: #052549;
        margin-bottom: 35px;
        line-height: 120%;

        text-align: center;

    }

    &__subtitle {
        color: #B3BAC3;
        //margin-bottom: 65px;
        margin-bottom: 80px;


        h3
        {
            margin-bottom: 35px;
            font-weight: 400;
            font-size: 26px;
            line-height: 120%;

            &:first-letter
            {
                margin-left: 1em;
            }
        }

        ul li
        {
            font-weight: 400;
            list-style: inside;
            font-size: 20px;
            line-height: 120%;
            margin-bottom: 10px;

        }

    }

    &__close {
        border-radius: 60px;
        background-color: var(--main-blue);
        width: 250px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 18px;
        color: #FFFFFF;

    }
}