@font-face {
    font-family: "SFProDisplay";
    src: url("../fonts/SF-Pro-Display-Regular.woff") format("woff"),
         url("../fonts/SF-Pro-Display-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "SFProDisplay";
    src: url("./../fonts/SF-Pro-Display-Medium.woff") format("woff"),
         url("./../fonts/SFProDisplay-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "SFProDisplay";
    src: url("./../fonts/SF-Pro-Display-Bold.woff") format("woff"),
         url("./../fonts/SF-Pro-Display-Semi-Bold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
}
