.donut-thin-chart {
    position: relative;

    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    path {
        filter: none
    }

    .chart-wrapper {
        position: relative;
        width: max-content;
        margin: 0 auto;
    }

    .center {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        width: calc(100% - 70px);
        height: calc(100% - 50px);
        top: 50%;
        left: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        background-color: #FFFFFF;
        box-shadow: 0px 57px 30px rgba(0, 51, 108, 0.05);

        .title {
            font-size: 16px;
            font-weight: 600;
            line-height: 30px;
            color: black
        }

        .subtitle {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.35);
        }

        h2 {
            font-family: 'SF Pro Display';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 74px;
            color: #052549;
            margin-right: -5px;

            .percant {
                font-size: 20px;
                margin-left: -10px;
            }
        }

        p {
            font-family: 'SF Pro Display';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #B3BAC3;
        }
    }
}