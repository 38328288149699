.range-picker-wrapper {
    position: relative;
    max-width: 235px;
    width: 100%;
    height: 50px;
    padding: 14px 20px;
    z-index: 3;

    background-color: #ffffff;
    border: 1.5px solid #EAEDF0;
    border-radius: 40px;

    .react-datepicker-wrapper {
        width: 162px;
        float: right;
    }
}

.date-picker-wrapper {
    .react-datepicker-wrapper {
        input {
            font-family: 'SF Pro Display';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            color: #B3BAC3;
        }

        input::placeholder {
            color: #B3BAC3;

        }
    }

    .react-datepicker__header {
        background-color: #ffffff;
        border-bottom: 1px solid #d0d9e2;
    }

    .react-datepicker__triangle::after {
        display: none;
        border-bottom-color: #ffffff;
    }

    .react-datepicker__triangle::before {
        display: none;
        border-bottom-color: transparent;
    }

    .react-datepicker__day--keyboard-selected {
        background-color: var(--main-blue);
    }

    .react-datepicker__day--selected {
        background-color: var(--main-blue);
    }

    .react-datepicker__day--in-range {
        background-color: var(--main-blue);
        box-shadow: 0px 12px 50px 9px rgba(111, 116, 206, 0.1);
    }

    .react-datepicker__current-month {
        color: #052549;
    }

    .react-datepicker {
        border: none;
        box-shadow: 0px 12px 50px 9px rgba(18, 28, 255, 0.16);
    }
}