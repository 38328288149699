.sidebar {
	min-width: 100px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	//width: 155px;
	height: 100%;
	//background: #fff;
	//box-shadow: 0px 4px 4px rgba(0, 51, 108, 0.05);
	padding: 40px 15px;

	@media (max-width: 1500px) {
		//padding: 30px 0;
		//width: 120px;
		padding: 40px 15px;
	}

	@media (max-width: 992px) {
		width: 100px;
	}

	&-list {
		overflow: auto;
		height: calc(100vh - 250px);
	}

	&-list li {
		display: block;
	}

	&-list li:not(:first-child) {
		margin-top: 20px;
	}

	&-link {
		margin: auto;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #f1f3f4;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: #052549;
		transition: all 0.3s ease-in-out;

		@media (max-width: 1500px) {
			width: 50px;
			height: 50px;
		}

		&.not-working {
			color: var(--main-red);
			background: rgba(255, 69, 69, 0.1);
		}

		&.working {
			color: var(--main-green);
			background: rgba(7, 195, 82, 0.1);
		}

		&.active,
		&:hover {
			color: var(--main-blue);
			background: #e0efff;
		}
	}

	&-button {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-btn {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--main-blue);
		margin: auto;

		img {
			width: 24px;
			object-fit: cover;
		}

		&:not(:last-child) {
			margin-bottom: 30px;
		}

		@media (max-width: 1500px) {
			width: 50px;
			height: 50px;
		}
	}

	&__inner {
		height: 100%;
		position: relative;
	}

	&__control {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.sidebar-logo {
	display: flex;
	align-items: center;
	justify-content: center;
	//margin: 0 auto 50px;
	//width: 72px;
	//height: 60px;

	width: 50px;
	margin-right: auto;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.modal-overflow .modal-content {
	overflow: initial;
}

.update {
	&-modal {
		.modal-content {
			max-width: 850px;
			max-height: 450px;
			overflow: initial;
		}
	}

	&-btn {
		max-width: 114px;
		padding: 14px 23px;
		background: var(--main-blue);
		border-radius: 60px;
		color: white;
	}
}

.modal-footer.update {
	margin: 0 0 0 auto;
	width: max-content;
	padding: 40px 0 40px 40px;
}

.main {
	width: 100%;
	//padding: 20px 50px 50px 50px;
	padding: 20px 20px 50px 20px;
	@media (max-width: 1500px) {
		padding: 20px;
	}

	&-card {
		//background: #ffffff;
		box-shadow: 0px 4px 6px rgba(0, 51, 108, 0.05);
		border-radius: 8px;
	}

	&-bottom {
		background: white;
	}

	&-calendar {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	&-date {
		width: 230px;
		position: relative;
	}

	&-img {
		position: absolute;
		top: 12px;
		left: 20px;
	}

	.date {
		background: #ffffff;
		border: 1.5px solid #eaedf0;
		border-radius: 40px;
		width: 100%;
		height: 50px;

		padding-left: 50px;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #b3bac3;

		&::placeholder {
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			color: #b3bac3;
		}
	}

	&-title {
		font-weight: 600;
		font-size: 24px;
		line-height: 40px;
		text-transform: capitalize;
		//color: #052549;
		color: white;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&-top {
		position: relative;
		z-index: 2;
		//padding: 18px;

		.caller-label {
			margin: 0;
		}

		.caller-wrapper {
			margin: 0;
		}
	}

	&-bottom {
		margin-top: 15px;
		overflow: auto;

		&.bottom {
			padding: 30px 0;
		}
	}

	&-content {
		border-bottom: 2px solid #f1f3f4;
		margin-bottom: 60px;

		&:last-child {
			margin-bottom: 0;
			border-bottom: none;
		}
	}

	&-footer {
		position: relative;
		padding: 30px 30px;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: #f1f3f4;
		}
	}

	&-search {
		max-width: 162px;

		&__icon {
			top: 8px;
		}
	}

	&-control {
		height: 38px;
	}
}

.customer {
	.main-form {
		min-height: 550px;
		//overflow-x: hidden;
	}
}

.checkbox {
	display: block;
	width: 20px;
	height: 20px;

	&-box {
		padding: 0px 0px 0px 30px;
		position: relative;
		cursor: pointer;
		font-size: 16px;
		line-height: 19px;
		color: #808d9c;
		transition: 0.3s linear;
	}

	&-input {
		opacity: 0;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

	&_border {
		display: flex;
		width: 20px;
		height: 20px;
		border-radius: 4px;
		background-color: #fff;
		position: absolute;
		top: 0;
		left: 0;
		border: 2px solid rgba(#004098, 0.5);
		transition: 0.15s ease-in-out;
	}

	&_bg {
		display: flex;
		position: absolute;
		top: 0px;
		left: 0px;
		width: 20px;
		height: 20px;
		background-image: url(../images/svg/checkbox.svg);
		background-repeat: no-repeat;
		opacity: 0;
		border-radius: 4px;
		transform: scale(0);
		transition: 0.15s ease-in-out;
	}

	&-title {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #b3bac3;
		margin-bottom: 10px;
	}

	&_con {
		font-weight: 500;
		font-size: 18px;
		line-height: 18px;
		color: #052549;
	}

	input[type="checkbox"] {
		&:checked,
		&:hover {
			& ~ .checkbox_con {
				color: #052549;
			}

			& ~ .checkbox_border {
				border-color: var(--main-blue);
			}
		}

		&:checked ~ .checkbox_bg {
			opacity: 1;
			background-image: url(../images/svg/checkbox.svg);
			border-radius: 4px;
			transform: scale(1);
		}
	}
}

.sending {
	&-input {
		background: #ffffff;
		border: 1px solid #f1f3f4;
		border-radius: 4px;
		padding: 17px;
		width: 100%;
	}
}

.history {
	&-items {
		background: #ffffff;
		border: 1.5px solid #f1f3f4;
		box-shadow: 0px 3px 6px rgba(0, 51, 108, 0.05);
		border-radius: 4px;
		padding: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		margin-bottom: 8px;
		transition: all 0.3s ease-in-out;

		&:hover {
			border: 1.5px solid var(--main-blue);
			box-shadow: 0px 3px 6px rgba(0, 51, 108, 0.05);

			.history-tel {
				color: var(--main-blue);

				span {
					color: var(--main-blue);
				}
			}
		}
	}

	&-call {
		display: flex;
		align-items: center;
	}

	&-number {
		width: 40px;
		height: 40px;
		flex-shrink: 0;
		background: #ffffff;
		border: 1px solid #f1f3f4;
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 12px;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #b3bac3;
	}

	&-tel {
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: #052549;
		transition: all 0.3s ease-in-out;

		span {
			color: #b3bac3;
			transition: all 0.3s ease-in-out;
		}
	}

	&-day-time {
		width: 100%;
		flex: 1 1 auto;
		padding-right: 10px;
		margin-top: 3px;
		margin-left: auto;
		font-size: 14px;
		text-align: right;
	}

	&-icon {
		//width: 24px;
		display: flex;
		gap: 5px;
		height: 24px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&-time {
		background: #e0efff;
		border-radius: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px 16px;
		color: var(--main-blue);
	}

	&-company {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #b3bac3;
		margin-top: 10px;

		span {
			color: #052549;
			margin-left: 5px;
		}
	}
}

table {
	overflow-x: auto;
	scroll-behavior: smooth;
}

.content-table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 1px;
	font-size: 0.9em;
	overflow-y: auto;

	.columns {
		&_checkbox {
			position: relative;
		}
		&_modal {
			min-width: 300px;
			position: absolute;
			top: 100%;
			left: 0;
			background-color: #fff;
			padding: 10px;
			box-shadow: 0 0 10px #eee;
			border-radius: 4px;
			z-index: 1;
			label {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding: 10px;
				user-select: none;
				transition: 0.1s;
				&:hover {
					background-color: #eee;
				}
				input {
					width: min-content;
					margin-right: 10px;
				}
			}
		}
	}
}

.patient-tr {
	cursor: pointer;
}

//.content-table tbody tr:hover{
//  box-shadow: 0 1px 2px rgba(0,0,0,.16), 0 0 0 1px #07f;
//}

.content-table thead tr {
	background-color: #f1f3f4;
	//box-shadow: 0 3px 6px rgba(0, 51, 108, 0.05);
	font-size: 14px;
	line-height: 17px;
	color: black;
	text-align: left;
	font-weight: bold;
	//text-transform: lowercase;
}

.content-table tbody tr {
	background: #ffffff;
	box-shadow: rgba(0, 0, 0, 0.02) 0px 6px 24px 0,
		rgba(0, 0, 0, 0.03) 0 0 0 1px;
	transition: all 0.3s ease-in-out;
	overflow: hidden;
	//height: 62px;

	&.active {
		box-shadow: rgba(0, 0, 0, 0.16) 0 1px 2px, rgb(0, 119, 255) 0 0 0 1px;
	}

	// &:hover {
	//   box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px,
	//     rgb(0, 119, 255) 0px 0px 0px 1px;
	// }
}

.content-table th,
.content-table td {
	padding: 10px 15px;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	/* text */
	// color: #052549;
}

.content-table tr td:nth-child(5) {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 216px;
}

.content-table td:last-child {
	white-space: pre;
}

.table-grey {
	border: 1px solid #eaedf0;
}

//.content-table tbody tr {
//  border-bottom: 1px solid #dddddd;
//}

.d-flex.column-gap-2 {
	gap: 12px;
}

.radio {
	display: inline-flex;
	align-items: center;
	margin-right: 20px;
	position: relative;
	cursor: pointer;

	&-input {
		opacity: 0;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		border-color: var(--main-blue);

		&:hover ~ {
			.radio-radio {
				border-color: var(--main-blue);
			}
		}

		&:checked ~ {
			.radio-radio {
				border-color: var(--main-blue);

				&::after {
					transform: translate(-50%, -50%) scale(1);
				}
			}
		}
	}

	&-radio {
		width: 22px;
		height: 22px;
		border: 1.5px solid var(--main-blue);
		border-radius: 50%;
		margin-right: 10px;
		padding: 2px;
		position: relative;
		transition: all 0.2s ease-in-out;

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(0);
			width: 12px;
			height: 12px;
			display: block;
			border-radius: 50%;
			background: var(--main-blue);
			transition: all 0.2s ease-in-out;
		}
	}
}

.flex-100 {
	flex: 1 1 auto;
}

.h-fit-content {
	height: fit-content;
}

.margin-top {
	margin-top: var(--margin-spacer, 1rem);
}

.margin-left {
	margin-left: auto;
}

.export-btn {
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 4px;
	padding: 10px 18px;
	font-weight: 500;
	color: #0077ff;
	border: 1px solid #0077ff;

	& img {
		margin-right: 10px;
	}
}

.export-btn svg {
	margin-right: 10px;
}

.export-btn svg path {
	fill: #0077ff;
}

.flex-main {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.flex-end {
	display: flex;
	justify-content: flex-end;
}

.mb-10 {
	margin-bottom: 10px;
}
.mb-30 {
	margin-bottom: 30px;
}

.min-w-100 {
	min-width: 100px;
}

.min-w-140 {
	min-width: 140px;
}

.min-w-500 {
	min-width: 500px;
	width: 100%;
}

.min-w-600 {
	width: 100%;
	min-width: 600px;
}

.min-w-700 {
	min-width: 700px;
}

.min-w-800 {
	min-width: 800px;
	width: 100%;
}

.operator-phone .modal-body {
	padding-left: 40px;
	padding-right: 40px;
}

.operator-phone .modal-content {
	border-radius: 10px;
}

.table-title {
	font-weight: 500;
	font-size: 18px;
	color: #052549;
	margin: 0 0 20px 0;
}

.audio-download-button {
	display: block;
	width: 24px;
	height: 24px;
	padding: 0;

	& svg {
		width: 100%;
		height: 100%;
	}
}

.audio-button {
	display: block;
	width: 24px;
	height: 24px;
	padding: 0;
}

.mr {
	&_10 {
		margin-right: 10px;
	}

	&_20 {
		margin-right: 20px;
	}
}

.ml {
	&_20 {
		margin-left: 20px;
	}
}

.w {
	&_full {
		width: 100%;
	}
}

.call-btn {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--main-blue);
	border-radius: 100%;
}

.drop-down {
	position: relative;

	&__call_window {
		opacity: 0;
	}
	&__inner {
		opacity: 0;
		position: fixed;
		max-width: 300px;
		z-index: 5000;
		pointer-events: none;
		background-color: #fff;
		transform: translate(-50%, 20px);
	}

	&_opened &__inner {
		opacity: 1;
		left: 50%;
		background-color: #fff;
		pointer-events: initial;
	}
}

.call_open {
	.drop-down {
		&__call_window {
			position: fixed;
			top: 65px;
			left: 70%;
			opacity: 1;
			background-color: #fff;
			border-radius: 8px;
			//overflow: hidden;
			box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
			pointer-events: initial;
			transition: 0.2s ease;
		}
	}

	.modal.open + .drop-down {
		.drop-down__call_window {
			left: 60% !important;
			right: -8%;
			top: 20%;
			transform: translate(120%, -50%);
		}
	}
}

.call__income {
	& {
		display: flex;
		background-color: #fff;
		min-width: 300px;
		padding: 20px 0 30px 0;
		flex-direction: column;
		align-items: center;
		box-shadow: 8px 10px 100px #eae8f0;
		border-radius: 6px;
		gap: 10px;
	}

	&-subtitle {
		font-size: 18px;
		margin-bottom: 10px;
		color: #b3bac3;
	}

	&-caller {
		font-weight: 500;
		font-size: 24px;
		margin: 0 0 10px 0;
	}

	&-phone {
		font-weight: 400;
		font-size: 18px;
		margin-bottom: 20px;
	}
}

.call-status {
	display: block;
	width: max-content;
	margin: 0 auto 25px auto;
	//color: #052549;
	color: white;
	font-size: 14px;
	font-weight: 700;
}

.call-keyboard {
	min-width: 250px;
	border-radius: 6px;
	background-color: #fff;
	padding: 24px 34px;

	&__label {
		font-size: 20px;
		margin-bottom: 10px;
		padding-bottom: 5px;
	}

	&__phone {
		font-weight: 500;
		font-size: 20px;
		font-family: "SFProDisplay";

		color: #000;
		height: 28px;

		padding-left: 5px;
		padding-bottom: 5px;
		margin-bottom: 10px;

		border-bottom: 1px solid #f5f5f6;
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	&__number {
		width: 55px;
		height: 55px;
		font-weight: 500;
		font-size: 24px;
		color: #494d51;
		background-color: #f5f5f7;
		border-radius: 100%;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__call,
	&__forward {
		width: 55px;
		height: 55px;
		display: block;
		align-items: center;
		justify-content: center;
		background-color: #07c352;
		// box-shadow: 0 8px 14px 0 #07C35240;
		border-radius: 100%;
		margin: 0 auto auto;
		padding: 0;
	}

	// mute
	&__mute,
	&__hold {
		width: 55px;
		height: 55px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fbb80f;
		border-radius: 100%;
		margin: 0 auto auto;
		padding: 0;
		box-shadow: 0 8px 14px 0 #fbb80f40;
		img {
			width: 20px;
			height: 20px;
		}
	}

	&__forward {
		background-color: #fff;
		// box-shadow: 0 8px 14px 0 rgba(0,0,0, .15)
	}

	&__call img,
	&__forward img {
		width: 18px;
		height: 18px;
	}

	&__hangup {
		width: 55px;
		height: 55px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #ff4545;
		border-radius: 100%;
		margin: 0 auto auto;
		padding: 0;
	}

	&__hangup img {
		width: 20px;
		height: 20px;
	}

	&__backspace {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		padding: 0;
		margin: auto;
		margin-top: 0;
	}

	&__backspace img {
		width: 20px;
		height: 20px;
	}
}

//call forward modal style

.call-forward__modal {
	position: absolute;
	width: 300px;
	height: 470px;
	left: calc(-100% - 320px);
	top: 0;
	background: #fff;

	padding: 10px;

	box-shadow: inherit;
	border-radius: inherit;

	&-title {
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		//color: #052549;
		margin-bottom: 10px;
	}

	&-subtitle {
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		//color: #052549;
		margin-bottom: 20px;
	}

	&-input {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.--left {
			flex: 1;
			border: 1px solid #08305ec5;
			border-radius: 4px;
			background: #ffffff;
			display: flex;
			justify-content: space-between;
			align-items: center;
			overflow: hidden;
			width: 100%;
		}
		input {
			background: #ffffff;
			width: 100%;
			height: 50px;
			padding: 15px;
			color: #08305ec5;
			outline: none;
		}
		button {
			background: var(--main-blue);
			border-radius: 6px;
			margin: 0 0 0 10px;
			height: 50px;
		}
	}

	&-prev {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px 10px 15px 15px;
		height: 50px;
		border-right: 0;
		background: #eeeeee;
	}

	&-lists {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-top: 10px;
		max-height: 265px;
		overflow: auto;
		overflow-x: hidden;
	}

	&-list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid lightgray;
		padding-top: 10px;
		&-left {
			h3 {
				margin-block: 0 10px;
			}
		}

		.call-keyboard__forward {
			margin: 0;
		}
	}

	// &-name {
	// }
	// &-num {
	// }
}

// APPLICATIONS

.application {
	display: flex;
	align-items: flex-start;
	overflow: auto;
	padding-left: 10px;

	&::-webkit-scrollbar {
		height: 0 !important;
	}

	&__card {
		position: relative;
		padding: 15px 10px;
		background: #ffffff;
		border: 1.5px solid transparent;
		box-shadow: 0 3px 6px rgba(0, 51, 108, 0.05);
		border-radius: 6px;
		font-weight: 500;
		color: #b3bac3;
		cursor: pointer;
		display: block;
	}

	&__card:hover {
		border: 1.5px solid #0077ff;
	}

	&__card-add {
		cursor: pointer;
		position: absolute;
		opacity: 0;
		top: 50%;
		left: 50%;
		z-index: 50;
		transform: translate(-50%, -50%) scale(0);
		width: 30px;
		height: 30px;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.2s ease-in-out;
		box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
	}

	&__card-add svg {
		width: 20px;
		height: 20px;
	}

	&__separator {
		position: relative;
		height: 10px;
		background-color: transparent;
	}

	&__separator-inner {
		position: absolute;
		top: -10px;
		bottom: -10px;
		left: 0;
		right: 0;
		z-index: 50;
	}

	&__separator-inner:hover + &__card-add {
		opacity: 1;
		transform: translate(-50%, -50%) scale(1);
	}

	&__card-add:hover {
		opacity: 1;
		transform: translate(-50%, -50%) scale(1);
	}

	&__card-add svg path {
		stroke: #fff;
	}

	&__client {
		margin: 0;
		font-weight: 500;
		font-size: 14px;
	}

	&__number {
		font-weight: 500;
		color: var(--main-blue);
		margin: 10px 0;
	}

	&__description {
		font-size: 18px;
	}

	&__tags {
		display: flex;
		align-items: center;
		margin-top: 10px;
	}

	&__tags > :not(:last-child) {
		padding-right: 15px;
		margin-right: 7px;
		position: relative;
	}

	&__tags > :not(:last-child)::after {
		content: "";
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		width: 8px;
		height: 8px;
		border-radius: 100%;
		background-color: #b3bac3;
	}

	&__add-board {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 25px 0;
		min-width: 300px;
		width: 300px;
		height: 76px;
		font-weight: 700;
		font-size: 18px;
		color: #b3bac3;
		border: 1px dashed #b3bac3;

		background-size: 100%;
		border-radius: 6px;
		z-index: 3;
	}

	&__add-board-between {
		display: none;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: calc(75px / 2);
		right: 0;
		width: 0;
		height: 0;
		transform: translateY(-50%) translateX(50%);
		transition: 0.2s;
		border-radius: 50%;
		background-color: #fff;
		z-index: 123;
		overflow: hidden;
		svg {
			width: 20px;
			height: 20px;
			path {
				stroke: black !important;
			}
		}
	}
	.board:hover {
		.application__add-board-between {
			display: flex;
			animation-name: animate;
			animation-duration: 0.3s;
			animation-fill-mode: forwards;
			@keyframes animate {
				from {
					width: 0;
					height: 0;
				}
				to {
					width: 30px;
					height: 30px;
				}
			}
		}
	}
}

.blackboard {
	min-width: 300px;
	width: 300px;
	height: calc(100vh - 120px);
	position: relative;

	&__actions {
		min-width: 350px;
		height: 600px;
		overflow-y: auto;
		border-radius: 10px;
		background-color: #fff;
		padding: 15px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	}

	&__action {
		color: #052549;
		font-size: 14px;
		font-weight: 500;
	}

	&__heading {
		padding: 20px 25px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: var(--main-blue);
		//border-top-left-radius: 6px;
		//border-top-right-radius: 6px;
	}

	&__name {
		font-weight: 700;
		font-size: 24px;
		padding: 4px;
		border-radius: 6px;
		color: #ffffff;
		background-color: transparent;
		transition: 0.1s ease-in-out;
		margin-right: 20px;
	}

	&__name:focus {
		background-color: #fff;
		color: #000;
	}

	&__count {
		min-width: 24px;
		max-width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		background-color: rgba(0, 0, 0, 0.1);
		color: #fff;
		font-weight: 700;
		font-size: 12px;
	}

	&__count svg {
		width: 100%;
	}

	&__body {
		padding: 0 5px;
		height: 100%;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		max-height: calc(100% - 85px);
		overflow: auto;
	}

	&__add-task {
		position: absolute;
		bottom: 40px;
		left: 50%;
		transform: translateX(-50%);
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
	}

	&__add-task svg path {
		stroke: #fff;
	}
}

.modal-test .mx-modal-wrapper {
	max-width: 800px !important;
	max-height: 800px !important;
}

.btn_copy {
	width: 18px;
	height: 18px;
	margin-left: 10px;
	padding: 0;

	& svg {
		width: 100%;
		height: 100%;
	}
}

.main-call-btn {
	position: fixed;
	bottom: 30px;
	right: 30px;
}

.color-radio {
	display: block;
	width: 25px;
	height: 25px;
	border-radius: 100%;
	position: relative;

	& > input:checked + &-active {
		opacity: 1;
	}

	&-active {
		display: block;
		position: absolute;
		top: -5px;
		left: -5px;
		right: -5px;
		bottom: -5px;
		border-radius: 100%;
		border: 2px solid #4aa9f8;
		opacity: 0;
	}
}

.board {
	&__title {
		color: #052549;
		margin: 0;
	}
}

.mt {
	&_20 {
		margin-top: 20px;
	}

	&_30 {
		margin-top: 30px;
	}
}

.mr {
	&_5 {
		margin-right: 5px;
	}
}

.w_24 {
	width: 24px;
}

.h_24 {
	height: 24px;
}

.p {
	&_0 {
		padding: 0;
	}

	&_25 {
		padding: 25px;
	}
}

.size_small .modal-select__control {
	min-height: 40px !important;
	height: 40px !important;
}

.select_xsm .modal-select__control {
	min-height: 35px !important;
	height: 35px;
	border-color: #f1f3f4 !important;
}

.task {
	&__modal-comment {
		display: block;
		font-weight: 500;
		font-size: 14px;
		text-transform: uppercase;
		color: #052549;
		opacity: 0.7;
	}

	&__description-box {
		border: 1px solid #f1f3f4;
		border-radius: 4px;
		padding: 10px;
	}

	&-btn {
		width: 100px;
		padding: 10px 0;
		border-radius: 6px;
		text-align: center;
	}

	&__create-btn {
		background-color: #0077ff;
		color: #fff;
		margin-right: 10px;
	}

	&__cancel-btn {
		color: #0077ff;
	}
}

.secondary-control {
	border: 2px solid #f1f3f4;
	border-radius: 4px;
}

.secondary-select .modal-select__control {
	min-height: 23px !important;
	height: 23px !important;
	font-size: 14px;
	border: 2px solid #f1f3f4 !important;
}

.secondary-select
	.modal-select__indicator.modal-select__dropdown-indicator
	svg {
	width: 16px;
	height: 16px;
}

.flex-max {
	flex: 1 1 0;
}

.margin-right {
	display: block;
	max-width: max-content;
	margin: auto 0 auto auto;
}

.circle-primary-blue circle {
	fill: var(--main-blue);
}

.no-border {
	& .modal-select__control {
		border: none !important;
		outline: none !important;
	}

	& .modal-select__control--is-focused {
		border: none !important;
		outline: none !important;
	}

	& .modal-select__control--menu-is-opened {
		border: none !important;
		outline: none !important;
	}
}

.no-notification {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	color: rgb(138, 148, 153);

	& svg {
		color: rgb(138, 148, 153);
		width: 60px;
		margin-bottom: 20px;
	}
}

.white-space_no-wrap {
	white-space: nowrap;
}

.reason_table {
	width: 95%;
	margin: 50px auto;
	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	tbody {
		cursor: pointer;
	}
}

.reason-modal {
	.mx-modal-wrapper {
		height: max-content;
	}
	.modal-content {
		border: none;
		border-radius: 20px;
		overflow: hidden;
	}
	.modal-body {
		padding: 30px;
	}
	form {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		.form-group {
			width: 100%;
		}
		input {
			margin-top: 10px;
			padding: 20px !important;
			border: 1px solid rgb(241, 243, 244);
			border-radius: 20px;
			margin-bottom: 10px;
		}
		button.btn {
			margin-left: auto;
		}
	}
}

.tippy-tooltip {
	padding: 0;
	background: transparent;
}

.cs-paginate-parent {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px;
}
.cs-paginate-left {
	display: flex;
	align-items: center;
	color: #b3bac3;

	span {
		color: #000;
		margin-left: 10px;
	}
}

.cs-paginate-right {
	color: #b3bac3;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
}

.cs-paginate {
	display: flex;
	//width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;

	li {
		cursor: pointer;
		background: #EBF0EF;

		a {
			color: #b3bac3;
			width: 100%;
			display: flex;
			min-width: 48px;
			padding: 10px 12px;
			text-align: center;
			border-radius: 40px;
			height: 40px;
			color: #58747A;
			justify-content: center;
		    align-items: center;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
		}

		&.selected {
			a {
				border: 1px solid #D7E0DF;
				background: #fff;
				color: #20383D;
			}
		}
		&:nth-child(2) {
			border-top-left-radius: 40px;
			border-bottom-left-radius: 40px;
		}
		&:nth-last-child(2) {
			border-top-right-radius: 40px;
			border-bottom-right-radius: 40px;
		}
	}

	.previous {
		margin-right: 8px;
	}
	.next {
		margin-left: 8px;
	}
	.previous, .next {
		border: 1px solid #D7E0DF;
		border-radius: 40px;
		background: #fff;
	}
	.previous.disabled, .next.disabled {
		border: 1px solid transparent;
		background: #EBF0EF;
	}
	li:first-child,
	li:last-child {
		a {
			color: currentColor;
		}
	}
}

.new_date_picker_container {
	display: flex;
	width: max-content;
	height: 50px;
	padding: 14px 20px;
	border-radius: 40px;
	border: 1.5px solid #eaedf0;
}
