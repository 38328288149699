//----------------------------------*\
@import "varables";

//----------------------------------*\
// LAYOUT
:root {
	--main-blue: #0263E0;
	--main-red: #D61F1F;
	--main-green: #07c352;
}

* {
	scroll-behavior: smooth;
	scrollbar-width: thin;
	scrollbar-color: #00000030 #fff;
	scrollbar-track-color: #00000030;

	&::-webkit-scrollbar {
		width: 10px;
		transition: 0.4s ease-in-out;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #fff;
		transition: 0.4s ease-in-out;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #00000030;
		border-radius: 20px;
		transition: 0.4s ease-in-out;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		//background: #00000030;
		transition: 0.4s ease-in-out;
	}
}

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	outline: none;

	padding: 0;
	margin: 0;
	&:hover {
		text-decoration: none;
	}
}

body {
	overflow-x: hidden !important;
}

p {
	margin: 0;
}

ul {
	padding: 0;
	margin: 0;

	li {
		list-style: none;

		a {
			text-decoration: none;
		}
	}
}

button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;

	&:focus {
		outline: none;
		box-shadow: none;
	}
}

.btn-check:focus + .btn,
.btn:focus {
	box-shadow: none;
}

a {
	color: #004098;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}

input,
textarea {
	border: none;
	outline: none;
	width: 100%;
	box-shadow: none;
}

textarea {
	resize: none;
}

label {
	padding: 0;
	margin: 0;
}

//margin-bottom => m-b-10
$spaceamounts: (
	0,
	5,
	10,
	15,
	20,
	25,
	30,
	35,
	40,
	45,
	50,
	55,
	60,
	65,
	70,
	75,
	80,
	85,
	90,
	95,
	100
);
$sides: (bottom, top, left, right);

@each $space in $spaceamounts {
	@each $side in $sides {
		.m-#{str-slice($side, 0, 1)}-#{$space} {
			margin-#{$side}: #{$space}px;
		}
	}
}

//padding-bottom => m-b-10
$spaceamounts: (
	0,
	5,
	10,
	15,
	20,
	25,
	30,
	35,
	40,
	45,
	50,
	55,
	60,
	65,
	70,
	75,
	80,
	85,
	90,
	95,
	100
);
$sides: (bottom, top, left, right);

@each $space in $spaceamounts {
	@each $side in $sides {
		.p-#{str-slice($side, 0, 1)}-#{$space} {
			padding-#{$side}: #{$space}px;
		}
	}
}

.bg {
	&_white {
		background-color: #fff;
	}
}

.container {
	max-width: 1170px;
	--bs-gutter-x: 15px;

	@media (max-width: 767px) {
		//--bs-gutter-x: 7.5px;
	}
}

.row {
	--bs-gutter-x: 8px;

	.centered-col {
		margin-left: auto;
		margin-right: auto;
	}

	// &:not(.no-mb) {
	//   margin-bottom: -30px;
	// }

	// &:not(.no-mb) > * {
	//   margin-bottom: 30px;
	// }

	@media (max-width: 767px) {
		--bs-gutter-x: 15px;

		&:not(.no-mb) {
			margin-bottom: -15px;

			& > * {
				margin-bottom: 15px;
			}
		}
	}
}

$main: #004098;
$secondary: #808d9c;
$stroke-color: #e8e8e8;
$text: #00275d;
$lightGray: #d4d6e3;

.site-wrapper {
	//background: #eaedf0;
	//flex-direction: column;
	min-height: calc(100vh - 80px);
	min-width: 355px;
	display: flex;
	font-family: "SFProDisplay";
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #000;
	//overflow: hidden;
	margin-top: 60px;
	//margin-left: 155px;
	margin-left: 102px;

	@media (max-width: 1500px) {
		margin-left: 100px;
	}

	@media (max-width: 992px) {
		margin-left: 100px;
	}
}

body {
	letter-spacing: 0;
	font-family: "SFProDisplay";
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	//color: #000;
	color: white;
	background: #eaedf0;
	overflow-x: hidden;
}

label {
	cursor: pointer;
}

.btn {
	background: var(--main-blue);
	border-radius: 60px;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;
	padding: 14px 28px;
	min-width: 141px;
	transition: all 0.3s ease-in-out;

	&:hover {
		color: #fff;
		box-shadow: 0px 8px 14px rgba(0, 119, 255, 0.25);
	}
}

#myChart {
	width: 100%;
	height: 300px;
}

#myChart2 {
	width: 240px;
}

#myChart3 {
	width: 240px;
}

.chartWrapper2 {
	width: 350px;
	height: 350px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto 30px auto;

	padding-bottom: 30px;

	&:last-child {
		margin: 0 auto;
	}
}

// modal

.modal {
	overflow: scroll;
	backdrop-filter: blur(4px);

	&-content {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		pointer-events: auto;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 0.3rem;
		outline: 0;
		height: max-content;
		overflow: auto;

		//	new added
		overflow-x: hidden;

		&.no-overflow {
			overflow: unset;
		}
	}

	.modal-header {
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: space-between;
		padding: 30px;
		border-bottom: 1px solid #dee2e6;
		border-top-left-radius: calc(0.3rem - 1px);
		border-top-right-radius: calc(0.3rem - 1px);
		max-height: 60px;

		//icon
		.call-history {
			width: calc(60px - 2rem);
			height: calc(60px - 2rem);
			cursor: pointer;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	&-dialog {
		max-width: 1190px;
	}
	
	&-body {
		max-height: 700px;
		padding: 10px 30px;
		padding-bottom: 20px;

		.history-container {
			position: absolute;
			transition: padding 0ms 300ms ease-in-out,
				max-width 300ms ease-in-out;
			max-width: 0;
			height: 0;
			overflow: hidden;
			top: 70px;
			right: 0;
			padding: unset;
			transition: all 0.3s ;

			&.active {
				max-width: 1070px;
				height: 100%;
				padding-right: 30px;
				
				.main-card{
					height: 100%;
				}
			}

		}
	}

	&-footer {
		display: flex;
		justify-content: flex-start;
		gap: 23px;
		//padding: 50px 40px 25px 0;
	}

	&-title {
		font-weight: 700;
		font-size: 20px;
		line-height: 28px;
		color: $inputSelectedText;
	}

	&-btn {
		color: #fff;
		padding: 14px 28px;
		border-radius: 4px;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		transition: 0.3s;
		border-radius: 32px;
		//width: 100px;

		&.blue {
			background: var(--main-blue);

			&:hover {
				background-color: #006ae4;
			}
		}

		&.red {
			background: var(--main-red);

			&:hover {
				background-color: #df3d3d;
			}
		}
		&:active {
			background-color: #fff;
			box-shadow: 0 2px #666;
			transform: translateY(1px);
		}
	}
}

.caller {
	&-type {
		position: absolute;
		width: max-content;
		background: #f1f3f4;
		border: 3px solid #f1f3f4;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;

		.caller-label {
			padding: 15px 20px;
			margin: 0;
			text-align: center;
		}

		.active {
			border-radius: 30px;
			background: #fff;
		}
	}

	&-number {
		font-weight: 700;
		font-size: 20px;
		line-height: 55px;
		color: $inputSelectedText;
		display: flex;
		align-items: center;
		position: absolute;
		left: 30px;
	}

	&-inner {
		display: flex;
		justify-content: space-between;
	}

	&-title {
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		margin-bottom: 18px;
		color: $inputSelectedText;
	}

	&-border {
		border: 2px solid #f1f3f4;
		padding: 15px !important;
	}

	&-shadow {
		box-shadow: none;
	}

	&-name {
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: $inputSelectedText;
		margin-bottom: 8px;
		width: 100%;
	}

	&-control {
		background: $inputBackground;
		border: 1px solid $inputBorderColor;
		border-radius: $inputRadius;
		width: 100%;
		height: 38px;
		padding: 15px;
		color: $inputSelectedText;
	}

	&-textarea {
		width: 100%;
		padding: 20px;
		resize: none;
		outline: none;
		background: #ffffff;
		border: 1px solid $inputBorderColor;
		border-radius: $inputRadius;
		min-height: 100px;
		color: $inputSelectedText;
	}

	&-textarea::placeholder {
		color: $inputPlaceholderText;
	}

	.input__wrapper {
		align-items: flex-start;
	}

	.input__wrapper
		.select2-container.select2-container--default
		.select2-selection {
		background: #ffffff;
		border: 1px solid #f1f3f4;
		border-radius: 4px;
		padding: 30px 15px;
		height: 61px;
	}

	&-wrapper {
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 20px;
	}
}

.history {
	max-height: 480px;
	overflow-y: auto;
	scroll-behavior: smooth;
	padding: 0 5px;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 20px;
	}

	&::-webkit-scrollbar-thumb,
	&::-webkit-scrollbar-thumb:hover {
		background-color: #00000030;
		transition: 4s ease-in-out;
	}

	&-operator {
		width: 40px;
		height: 40px;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		background: #ffffff;
		border: 1px solid #a0a0a0;
		border-radius: 50px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		margin-right: 12px;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #000;
	}
}

.modal-backdrop.show {
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
}

.mb-0 {
	margin-bottom: 0 !important;
}

.content-table {
	&__call svg {
		width: 24px;
		height: 24px;
	}

	&__call path {
		fill: #4976ce;
		stroke: #4976ce;
	}

	//padding: 30px;
	tbody tr:hover {
		//outline-width: 1.5px;
		//outline-style: solid;
		background: #EBF4FF;
	}

	tbody tr {
		outline-color: var(--main-blue);
		outline-offset: -1px;
		transition: none;
	}
}

// Handbook Table

.table-select {
	width: 100%;

	&__menu {
		z-index: 200;

		::-webkit-scrollbar {
			width: 5px;
		}

		::-webkit-scrollbar-thumb {
			background-color: #00000030;
			border-radius: 5px;
		}
	}
}

.table-select__control {
	background: $inputBackground !important;
	border: 1px solid $inputText !important;
	border-radius: $inputRadius !important;
	min-height: 44px !important;
	min-width: 140px;
}

.table-select__input-container,
.table-select__single-value,
.table-select__placeholder {
	padding: 0;
	font-family: SFProDisplay;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: $inputSelectedText !important;
}

.audio {
	position: absolute;
	visibility: hidden;
	pointer-events: none;
	//bottom: 0;
	//left: 0;
	//width: 100%;
	//transition: 0.2s ease-in-out;
	//transform: translateY(100%);

	&_active {
		transform: translateY(0);
	}
}

.caller-label {
	margin-bottom: 20px;
	display: block;
}

.cursor_pointer {
	cursor: pointer;
}

.service-tooltip {
	position: absolute;
	bottom: calc(100% + 20px);
	left: 50%;
	z-index: 10;
	transform: translate(-50%, 10px);
	background: #ffffff;
	border: 1px solid #b3bac3;
	box-shadow: 0 3px 6px rgba(0, 51, 108, 0.2);
	border-radius: 6px;
	padding: 10px;
	transition: 0.2s ease-in-out;
	visibility: hidden;
	opacity: 0;

	&_active {
		visibility: visible;
		opacity: 1;
		transform: translate(-50%, 0);
	}

	&__item {
		font-size: 14px;
		color: $inputSelectedText;
		white-space: nowrap;
	}

	&__item:not(:last-child) {
		margin-bottom: 5px;
	}

	&-wrapper {
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		border: 1px solid #f1f3f4;
		font-weight: 500;
		font-size: 14px;
		color: #b3bac3;
		position: relative;
		cursor: pointer;

		& img {
			position: absolute;
			top: 0;
			right: -3px;
		}
	}
}

.modal-select_menu {
	z-index: 2;
}

.modal-select_menuPortal::-webkit-scrollbar {
	display: none;
}

// Tostify

.Toastify__toast-theme--light {
	background: rgba(0, 0, 0, 0.6) !important;
	color: white !important;
}

.Toastify__close-button {
	color: white !important;
	opacity: 0.4 !important;
}

.Toastify__progress-bar.Toastify__progress-bar--error {
	background-color: var(--main-red);
}

.Toastify__toast-icon {
	--toastify-icon-color-error: var(--main-red);
}

.form-input {
	color: inherit;
}

.customer-table {
	padding: 0;

	& .content-table {
		padding: 30px;
	}
}

.operator-update-control {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	& button:first-child {
		margin-right: 20px;
	}
}

.update-modal .row {
	width: 100%;
}

.caller-control input {
	color: inherit;
}

.analytics {
	.main-bottom {
		overflow: initial;
		&.bottom {
			display: flex;
			@media screen and (max-width: 880px) {
				flex-direction: column;
				gap: 60px;
			}
			.main-content {
				margin-bottom: 0;
				flex: 1;

				.donut-chart {
					display: flex;
					flex-direction: column;
					gap: 20px;
				}
			}
		}
	}
}

//----------------------------------*/
.table-td-blue tbody td {
	color: #052549;
}

.import-btn {
	&_blue {
		display: flex;
		align-items: center;
		font-weight: 500;
	}

	&_blue svg {
		margin-right: 10px;
	}

	&_blue svg path {
		fill: #fff;
	}
}

@import "fonts";
@import "header";
@import "footer";
@import "index";
@import "pbx";

.btn_red {
	background: var(--main-red);
	box-shadow: 0px 8px 14px rgba(255, 69, 69, 0.25);
}

.App-main {
	width: 100%;
	//new added
	//top: 22px;
	padding-top: 20px;
	position: relative;
}

.modal-select__placeholder {
	color: rgba(8, 48, 94, 0.5) !important;
}

.react-daterange-picker__wrapper {
	border: none;
}

.main-date {
	width: unset;
}

.service-textarea {
	padding: 8px;
	background: #fff !important;
	border: 1px solid #08305ec5 !important;
	border-radius: 4px !important;
	min-height: 50px !important;
	min-width: 140px;
	color: #08305ec5 !important;
}

.textarea_cs {
	background: #fff !important;
	border: 1px solid #08305ec5 !important;
	border-radius: 4px !important;
	color: #08305ec5;
	font-family: inherit;
	//min-height: 50px !important;
	//min-width: 140px;
	//display: flex;
	//align-content: center;
}
.main-bottom {
	overflow: unset;
}

.content-table {
	thead {
		position: sticky;
		top: 0;
	}
}

.content-table-parent {
	width: 100%;
	overflow: auto;
	max-height: 1482px;
}

.main.call-center {
	.content-table-parent {
		overflow: unset;
	}
}

.filter {
	position: fixed;
	right: 5px;
	top: 65px;
	bottom: 5px;
	background: white;
	box-shadow: 0 3px 6px rgba(0, 51, 108, 0.2);
	padding: 32px;
	z-index: 1;
	border-radius: 8px;

	&-columns {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.close-btn {
		position: absolute;
		right: 12px;
		top: 12px;
		background: rgba(235, 240, 239, 1);
		padding: 4px;
		border-radius: 100px;
		width: 32px;
		height: 32px;

		img {
			width: 8px;
			height: 8px;
		}
	}
}

.theme-background-image {
	background-size: cover !important;
	background-repeat: no-repeat;
	background-position-x: 0px;
	background-position-y: 0px;
	background-attachment: fixed;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.export-type-btn {
	background: rgba(255, 255, 255, 0.2);
	display: flex;
	gap: 5px;
	align-content: center;
	align-items: center;
}

.export-type-btn:hover {
	background: rgba(255, 255, 255, 0.4);
}