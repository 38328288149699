.notification {
	&__trigger {
		position: relative;
	}

	&__badge {
		font-size: 12px;
		width: 17px;
		height: 17px;
		color: #fff;
		font-weight: 500;
		position: absolute;
		top: -5px;
		right: -7px;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #d60000;
	}

	&__card {
		position: relative;
		min-width: 300px;
		width: 100%;
		padding: 10px 20px;
		border-radius: 10px;
		box-shadow: rgba(0, 0, 0, 0.20) 0px 5px 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #052549;
	}

	&__delete {
		position: absolute;
		top: 12px;
		right: 12px;
		width: 15px;
		height: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 3px;
		border-radius: 100%;
		background-color: rgba(0, 119, 255, 0.1);
	}

	&__delete svg {
		width: 10px;
		height: 10px;
	}

	&__card:not(:first-child) {
		margin-top: 15px;
	}

	&__info{
		width: 80%;
	}

	&__user {
		font-weight: 500;
		font-size: 16px;
		margin: 0 0 0 0;
	}

	&__phone {
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 5px;
	}

	&__description {
		color: rgba(0, 0, 0, 0.4);
		font-weight: 400;
		font-size: 14px;
		line-height: 100%;
		margin-top: 10px;
	}
}
